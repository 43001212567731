<template>
  <section class="ratio_landscape blog-list-section p-3">
    <div class="container">
      <div class="row">
        <div class="col-xl-3 col-lg-4">
          <div class="sticky-cls blog-sidebar">
            <div class="filter-cards">
              <div class="advance-card">
                <h6>{{$t('Category')}}</h6>
                <CategorySection />
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-9 col-lg-8">
          <div class="blog-list row">
            <NewsCardVue
              v-for="(news, index) in paginationNews(perPage, currentPage)"
              :key="index"
              :news="news"
            />
          </div>
          <nav class="theme-pagination">
            <b-pagination
              v-model="currentPage"
              :total-rows="filterCombinedNews.length"
              :per-page="perPage"
              @change="onPageChanged"
            ></b-pagination>
          </nav>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { BPagination } from "bootstrap-vue";
import NewsCardVue from "../components/news/NewsCard.vue";
import CategorySection from "../components/news/CategorySection.vue";

export default {
  components: {
    BPagination,

    NewsCardVue,
    CategorySection
  },
  data() {
    return {
      perPage: 5,
      currentPage: 1,
      category: 0,
    };
  },
  created() {
    if (this.$route.params.categoryid) {
      this.category = this.$route.params.categoryid;
    } else {
      this.category = 0;
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.categoryid) {
      this.category = to.params.categoryid;
    } else {
      this.category = 0;
    }
    next();
  },
  methods: {
    onPageChanged(page) {
      this.currentPage = page;
      window.scrollTo(0, 0);
    },
  },
  computed: {
    realEstateNews() {
      return this.$store.getters.getRealEstateNews;
    },
    landscapeDesignNews() {
      return this.$store.getters.getLandscapeDesignNews;
    },
    combinedNews() {
      return this.$store.getters.getAllNews;
    },
    filterCombinedNews() {
      return this.combinedNews.filter((item, index) => {
        switch (this.category) {
          case 1:
            return index < this.$store.getters.getRealEstateNewsLength;
          case 2:
            return index >= this.$store.getters.getRealEstateNewsLength;
          default:
            return true;
        }
      });
    },
    paginationNews() {
      return (page_size, page_number) =>
        this.filterCombinedNews.slice(
          (page_number - 1) * page_size,
          page_number * page_size
        );
    },
  },
};
</script>
