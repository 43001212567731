<template>
  <div>
    <Loader />
    <TapToTop />
    <VueScrollFixedNavbar>
      <Header />
    </VueScrollFixedNavbar>
    <div @click="closeNav">
    <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import Loader from "./components/Loader.vue";
import TapToTop from "./components/TapToTop.vue";
import { VueScrollFixedNavbar } from "vue-scroll-fixed-navbar";
export default {
  components: {
    Header,
    Footer,
    Loader,
    TapToTop,

    VueScrollFixedNavbar,
  },
  data() {
    return {};
  },
  created() {
    this.$store.dispatch("getNews");
  },
  mounted() {},
  methods: {
    closeNav(){
      this.$store.commit('setNav', false);
    }
  },
  watch: {},
};
</script>
