<template>
  <div class="category-property">
    <ul>
      <li>
        <b-link :to="{ name: 'news-list' }">
          <b-img class="icon-description"
            width="32px"
            height="32px"
            src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1664110084/bds_tbd/icons/rhombus_dsahdz.svg"
            alt="" />
          {{ $t("All") }}
          <span class="float-end">({{ combinedNewsLength }})</span>
        </b-link>
      </li>
      <li>
        <b-link :to="{ name: 'news-list', params: { categoryid: 1 } }">
          <b-img class="icon-description"
            width="32px"
            height="32px"
            src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1664110084/bds_tbd/icons/rhombus_dsahdz.svg"
            alt="" />
          {{ $t("Real estate") }}
          <span class="float-end">({{ realEstateNewsLength }})</span>
        </b-link>
      </li>
      <li>
        <b-link :to="{ name: 'news-list', params: { categoryid: 2 } }">
          <b-img class="icon-description"
            width="32px"
            height="32px"
            src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1664110084/bds_tbd/icons/rhombus_dsahdz.svg"
            alt="" />
          {{ $t("Landscape design") }}
          <span class="float-end">({{ landscapeDesignNewsLength }})</span>
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLink, BImg } from "bootstrap-vue";
export default {
  components: {
    BLink,
    BImg
  },
  computed: {
    realEstateNewsLength() {
      return this.$store.getters.getRealEstateNewsLength;
    },
    landscapeDesignNewsLength() {
      return this.$store.getters.getLandscapeDesignNewsLength;
    },
    combinedNewsLength() {
      return this.$store.getters.getAllNewsLength;
    },
  },
};
</script>

<style>

</style>
