<template>
  <section class="feature-section layout9">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="title-1 text-white">
            <span class="label label-gradient color-6">{{ $t("Field") }}</span>
            <h2>
              {{ $t("Some of the most prominent areas of the Pacific group") }}
            </h2>
            <hr />
          </div>
          <VueSlickCarousel :fade="true" class="feature-1 arrow-light">
            <div v-for="(property, index) in properties" :key="index">
              <div class="feature-wrapper">
                <div class="row">
                  <div class="col-xl-4 col-lg-3">
                    <div class="feature-left">
                      <div class="property-details">
                        <span class="font-roboto">{{
                          $t("PACIFIC GROUP")
                        }}</span>
                        <a href="#">
                          <h3 class="d-flex">
                            {{ $t(property.name) }}
                            <span
                              ><span class="label label-dark label-pill">{{
                                $t("SERVICE")
                              }}</span></span
                            >
                          </h3>
                        </a>
                        <p class="font-roboto">
                          <b-img
                            class="icon-description"
                            width="32px"
                            height="32px"
                            src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1664110084/bds_tbd/icons/rhombus_dsahdz.svg"
                            alt=""
                          />
                          {{ $t(property.description) }}
                        </p>
                        <a href="#">
                          <span class="round-half color-6">
                            <heart-icon />
                          </span>
                        </a>
                        <div class="property-btn">
                          <b-link
                            :to="{ name: property.viewMoreUrl }"
                            class="btn btn-gradient color-6 btn-pill"
                            tabindex="0"
                            >{{ $t("View more") }}</b-link
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-8 col-lg-9 order-md">
                    <div
                      class="feature-image bg-size"
                      :style="{
                        backgroundImage: `url(${publicImagePath}main/${property.image})`,
                      }"
                      style="background-size: cover; background-position: center center; background-repeat: no-repeat; display: block;"
                    >
                      <img
                        :src="property.image"
                        class="bg-img"
                        alt=""
                        :style="{ display: 'none' }"
                      />
                      <h4>{{ $t("PACIFIC GROUP") }}</h4>
                      <span class="box-color"></span>
                      <span class="signature">
                        <b-img
                          src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1664360235/bds_tbd/icons/Pacific-01_a4czmc.svg"
                          alt="signature"
                        />
                      </span>
                      <span class="label label-white label-lg color-6">{{
                        $t("Images")
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </VueSlickCarousel>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { BLink, BImg } from "bootstrap-vue";
import { HeartIcon } from "vue-feather-icons";
export default {
  components: {
    VueSlickCarousel,
    HeartIcon,
    BLink,
    BImg,
  },
  data() {
    return {
      properties: [
        {
          name:
            "Consulting on investment in urban real estate, resorts & industrial parks",
          description:
            "Real estate is always the ideal market for investors and customers. After the effects of the pandemic, the real estate market has had huge fluctuations in prices...",
          image: "23ab7e86aee569bb30f4.webp",
          viewMoreUrl: "real-estate-sale",
        },
        {
          name: "Landscape design consultancy",
          description:
            "Landscape design is the work of arranging, arranging and designing in detail greenery items including specific divided jobs. The projects that often use this service are gardens, resorts, villas, parks, corporate landscapes, apartment complex landscapes ...",
          image: "8d511862912955770c38_bh7xo5.webp",
          viewMoreUrl: "landscape-design",
        },
      ],
    };
  },
  computed: {
    publicImagePath() {
      return this.$store.state.publicImagePath;
    },
  },
};
</script>
