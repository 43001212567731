<template>
  <div class="col-xl-8 col-lg-8 content" v-html="content">
  </div>
</template>

<script>
export default {
  props:{
    landscape_id: Number
  },
  computed:{
    content() {
      return this.$store.getters['getLandscapePackagesById'](this.landscape_id).content;
    }
  }
};
</script>

<style scoped>
  .content ::v-deep h1,
  .content ::v-deep td,
  .content ::v-deep p {
    text-align: center;
  }

  .content ::v-deep p {
    color: #000000;
  }

  .content ::v-deep table {
    width: 100%;
    height: 144px;
    margin: 15px 0;
  }

  .content ::v-deep .bg-img {
    width: 100%;
    object-fit: cover;
    height: 400px;
  }

  .content ::v-deep .aligncenter {
    width: 100%;
  }

  .content ::v-deep .custombutton {
    display: inline-block;
    border-radius: 14px;
    background-color: #1973d1;
    border: none;
    color: #ffffff;
    font-weight: bolder;
    text-align: center;
    font-size: 20px;
    padding: 10px;
    width: 250px;
    transition: all 0.25s;
    cursor: pointer;
    margin: 5px;
  }

  .content ::v-deep .custombutton span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }

  .content ::v-deep .custombutton:hover span {
    padding-right: 25px;
  }

  .content ::v-deep .custombutton:hover span:after {
    right: 0;
  }

  .content ::v-deep .custombutton span:after {
    content: "\00bb";
    position: absolute;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }

  .content ::v-deep thead,
  .content ::v-deep tbody,
  .content ::v-deep tfoot,
  .content ::v-deep tr,
  .content ::v-deep td,
  .content ::v-deep th {
    border-width: 1px;
  }

  .content ::v-deep .justify-text{
    text-align:justify; 
    margin: 10px 0;
  }

  .content ::v-deep .flex-column{
    display:flex; 
    flex-direction:column;
  }

  .content ::v-deep h4{
    font-weight: bold;
  }

  .content ::v-deep .center-text{
    text-align:center; 
    margin: 10px 0;
  }

  .content ::v-deep .flex-column li{
    display: list-item;
    margin: 5px 5px;
  }
</style>
