<template>
  <section class="error-section small-section">
    <div class="container">
      <div class="row">
        <div class="col text-center not-found">
          <img
            src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1664245253/bds_tbd/others/2_1_bjxpot.svg"
            class="img-fluid"
            alt=""
          />
          <h2>{{$t('Whoops! something went wrong ?')}}</h2>
          <p class="font-roboto">
            {{$t("we are sorry but the page you are looking for doesn't exist or has been removed. please check or search again.")}}
          </p>
          <div class="btns">
            <b-link :to="{ name: 'home' }" class="btn btn-gradient color-2"
              >{{$t('go to home page')}}</b-link
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { BLink } from 'bootstrap-vue';
export default {
    components: {
        BLink
    }
};
</script>

<style></style>
