import e from "vue";
import a from "vuex";
import s from "axios";
e.use(a);
export default new a.Store({
  state: {
    news: { realEstateNews: [], landscapeDesignNews: [] },
    isLoading: !0,
    isNavbarOpen: !1,
    publicImagePath: process.env.BASE_URL + "assets/images/",
    properties: [
      {
        id: 1,
        name: "Dự \xe1n Bất động sản Cao Phong",
        address: "Hợp Phong / Cao Phong / Ho\xe0 B\xecnh",
        price: "3,8 Tỷ",
        size: "3678m / 400m Thổ Cư",
        designImage:
          "https://res.cloudinary.com/dlzbwakoc/image/upload/v1665840252/bds_tbd/real-estate/1/AnyConv.com__f5e946b21679d1278868_oyy52e.webp",
        images: [
          "https://res.cloudinary.com/dlzbwakoc/image/upload/v1665836826/bds_tbd/real-estate/1/dc57433a13f1d4af8de04_seh6kk.webp",
          "https://res.cloudinary.com/dlzbwakoc/image/upload/v1665836826/bds_tbd/real-estate/1/a4f5469b1650d10e88418_akawnl.webp",
          "https://res.cloudinary.com/dlzbwakoc/image/upload/v1665836826/bds_tbd/real-estate/1/5d1e4b731bb8dce685a91_bkic2m.webp",
          "https://res.cloudinary.com/dlzbwakoc/image/upload/v1665836826/bds_tbd/real-estate/1/87312b5c7b97bcc9e5865_jz4erg.webp",
          "https://res.cloudinary.com/dlzbwakoc/image/upload/v1665836826/bds_tbd/real-estate/1/bbd579bb2970ee2eb7617_sbhl6y.webp",
          "https://res.cloudinary.com/dlzbwakoc/image/upload/v1665836827/bds_tbd/real-estate/1/d12aa547f58c32d26b9d6_ndnpn9.webp",
          "https://res.cloudinary.com/dlzbwakoc/image/upload/v1665836827/bds_tbd/real-estate/1/b27e621332d8f586acc92_bvz64e.webp",
          "https://res.cloudinary.com/dlzbwakoc/image/upload/v1665836825/bds_tbd/real-estate/1/b7891fe44f2f8871d13e3_q2k4b2.webp",
          "https://res.cloudinary.com/dlzbwakoc/image/upload/v1665840252/bds_tbd/real-estate/1/AnyConv.com__f5e946b21679d1278868_oyy52e.webp",
        ],
        video: "",
      },
      {
        id: 2,
        name: "Dự \xe1n Bất động sản Y\xean Thủy",
        address: "Đa Ph\xfac / Y\xean Thuỷ / Ho\xe0 B\xecnh",
        price: "1,7 Tỷ",
        size: "2100m / 1025m Thổ Cư",
        designImage:
          "https://res.cloudinary.com/dlzbwakoc/image/upload/v1665840507/bds_tbd/real-estate/2/AnyConv.com__3695182748ec8fb2d6fd_enjfyy.webp",
        images: [
          "https://res.cloudinary.com/dlzbwakoc/image/upload/v1665840505/bds_tbd/real-estate/2/AnyConv.com__53c09873c8b80fe656a9_yocatb.webp",
          "https://res.cloudinary.com/dlzbwakoc/image/upload/v1665840507/bds_tbd/real-estate/2/AnyConv.com__210fc5bc957752290b66_xcvezk.webp",
          "https://res.cloudinary.com/dlzbwakoc/image/upload/v1665840507/bds_tbd/real-estate/2/AnyConv.com__fdde509e0a55cd0b9444_swafvc.webp",
          "https://res.cloudinary.com/dlzbwakoc/image/upload/v1665840507/bds_tbd/real-estate/2/AnyConv.com__96bc590209c9ce9797d8_a3quuq.webp",
          "https://res.cloudinary.com/dlzbwakoc/image/upload/v1665840507/bds_tbd/real-estate/2/AnyConv.com__e889df3a8ff148af11e0_igssst.webp",
          "https://res.cloudinary.com/dlzbwakoc/image/upload/v1665840507/bds_tbd/real-estate/2/AnyConv.com__3695182748ec8fb2d6fd_enjfyy.webp",
          "https://res.cloudinary.com/dlzbwakoc/image/upload/v1665840507/bds_tbd/real-estate/2/AnyConv.com__b2af531c03d7c4899dc6_pheohx.webp",
        ],
        video: "",
      },
      {
        id: 3,
        name: "Dự \xe1n Bất động sản T\xe2n Lạc",
        address: "Gia M\xf4 / T\xe2n Lạc / Ho\xe0 B\xecnh",
        price: "2,2 Tỷ",
        size: "2625m / 1000m Thổ Cư",
        designImage:
          "https://res.cloudinary.com/dlzbwakoc/image/upload/v1665840627/bds_tbd/real-estate/3/AnyConv.com__8d4d1e7a4fb188efd1a0_qc2aua.webp",
        images: [
          "https://res.cloudinary.com/dlzbwakoc/image/upload/v1665840629/bds_tbd/real-estate/3/AnyConv.com__144dce7d9fb658e801a7_unz1uu.webp",
          "https://res.cloudinary.com/dlzbwakoc/image/upload/v1665840628/bds_tbd/real-estate/3/AnyConv.com__126ab55ae49123cf7a80_dhyy60.webp",
          "https://res.cloudinary.com/dlzbwakoc/image/upload/v1665840628/bds_tbd/real-estate/3/AnyConv.com__90db2aeb7b20bc7ee531_onujos.webp",
          "https://res.cloudinary.com/dlzbwakoc/image/upload/v1665840628/bds_tbd/real-estate/3/AnyConv.com__90db2aeb7b20bc7ee531_onujos.webp",
          "https://res.cloudinary.com/dlzbwakoc/image/upload/v1665840628/bds_tbd/real-estate/3/AnyConv.com__c30ef339a2f265ac3ce3_womp36.webp",
          "https://res.cloudinary.com/dlzbwakoc/image/upload/v1665840627/bds_tbd/real-estate/3/AnyConv.com__d4b9aa8efb453c1b6554_bhcbtg.webp",
        ],
        video: "",
      },
      {
        id: 4,
        name: "Dự \xe1n Bất động sản Cao Phong",
        address: "Hợp Phong / Cao Phong / Ho\xe0 B\xecnh",
        price: "1 Tỷ 180 Triệu",
        size: "1500m / 400m Thổ Cư",
        designImage:
          "https://res.cloudinary.com/dlzbwakoc/image/upload/v1665840749/bds_tbd/real-estate/4/AnyConv.com__823f6daa3c61fb3fa270_z7koyd.webp",
        images: [
          "https://res.cloudinary.com/dlzbwakoc/image/upload/v1665840749/bds_tbd/real-estate/4/AnyConv.com__f78bded2851942471b08_jk1qn5.webp",
          "https://res.cloudinary.com/dlzbwakoc/image/upload/v1665840749/bds_tbd/real-estate/4/AnyConv.com__ecd21147408c87d2de9d_tqfe0e.webp",
          "https://res.cloudinary.com/dlzbwakoc/image/upload/v1665840749/bds_tbd/real-estate/4/AnyConv.com__8ae6cd739cb85be602a9_rja9wv.webp",
          "https://res.cloudinary.com/dlzbwakoc/image/upload/v1665840749/bds_tbd/real-estate/4/AnyConv.com__823f6daa3c61fb3fa270_z7koyd.webp",
        ],
        video:
          "https://res.cloudinary.com/dlzbwakoc/video/upload/v1665841203/bds_tbd/real-estate/4/4393625391224985905_perqcf.mp4",
      },
    ],
    landscapePackages: [
      {
        id: 1,
        name: "SÂN VƯỜN, TIỂU CẢNH",
        bigImage: 'https://res.cloudinary.com/dlzbwakoc/image/upload/v1665971010/bds_tbd/landspace/bao-gia-thiet-ke-1024x449_nsfjka.webp',
        content: `<div class="blog-single-detail theme-card">
      <div class="blog-detail-image">
        <img
          loading="lazy"
          src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1665971010/bds_tbd/landspace/bao-gia-thiet-ke-1024x449_nsfjka.webp"
          class="bg-img img-fluid"
          alt=""
        />
      </div>
      <div class="blog-title">
        <ul class="post-detail">
          <li>13 Tháng 9 2022</li>
          <li>Đăng bởi : Admin</li>
        </ul>
        <h1>Báo giá thiết kế Thái Bình Dương</h1>
      </div>
      <div class="details-property">
        <div class="row">
          <div class="col medium-9 small-12 large-9">
            <div class="col-inner">
              <p>
                <strong
                  >Bạn đang quan tâm đến Dịch vụ thiết kế Thái Bình Dương? Bạn
                  đang muốn biết chi phí thiết kế Cảnh quan, Sân vườn, Công
                  trình kiến trúc?</strong
                >
              </p>
              <h2>
                <em
                  ><span style="color: #0c3baa;">Thái Bình Dương </span>xin gửi
                  tới Quý khách hàng bảng báo giá thiết kế như sau:</em
                >
              </h2>
              <h3>
                <span style="color: #0c3baa;"
                  >I. BÁO GIÁ THIẾT KẾ CÔNG TRÌNH CẢNH QUAN, SÂN VƯỜN<b
                    >:&nbsp;<b>(VNĐ/m2)</b></b
                  ></span
                >
              </h3>
              <table>
                <tbody>
                  <tr style="height: 24px;">
                    <td style="width: 8.24242%; height: 48px;" rowspan="2">
                      <strong>MH</strong>
                    </td>
                    <td style="width: 24.6061%; height: 48px;" rowspan="2">
                      <strong>Diện tích vườn(m2)</strong>
                    </td>
                    <td style="width: 41.4545%; height: 24px;" colspan="4">
                      Suất đầu tư dự kiến(triệu đồng)/m2
                    </td>
                    <td style="width: 23.5152%; height: 24px;">
                      <strong>Ghi chú</strong>
                    </td>
                  </tr>
                  <tr style="height: 24px;">
                    <td style="width: 10.7879%; height: 24px;">
                      &lt;2,5
                    </td>
                    <td style="width: 10.1818%; height: 24px;">
                      2,5 – 3.5
                    </td>
                    <td style="width: 10.303%; height: 24px;">
                      3.5 – 4.5
                    </td>
                    <td style="width: 10.1818%; height: 24px;">
                      &gt;4.5
                    </td>
                    <td style="width: 23.5152%; height: 120px;" rowspan="5">
                      Đơn giá thực hiện thiết kế và giao hồ sơ theo quy trình
                      thiết kế Thái Bình Dương.
                    </td>
                  </tr>
                  <tr style="height: 24px;">
                    <td style="width: 8.24242%; height: 24px;">
                      A1001
                    </td>
                    <td style="width: 24.6061%; height: 24px;">
                      70 –&nbsp;200
                    </td>
                    <td style="width: 10.7879%; height: 24px;">
                      &nbsp;140,000
                    </td>
                    <td
                      style="background-color: #1973d1; width: 10.1818%; height: 24px;"
                    >
                      160,000
                    </td>
                    <td style="width: 10.303%; height: 24px;">
                      180,000
                    </td>
                    <td
                      style="background-color: #1973d1; width: 10.1818%; height: 24px;"
                    >
                      200,000
                    </td>
                  </tr>
                  <tr style="height: 24px;">
                    <td style="width: 8.24242%; height: 24px;">
                      A1002
                    </td>
                    <td style="width: 24.6061%; height: 24px;">
                      201&nbsp;–&nbsp;300
                    </td>
                    <td
                      style="background-color: #1973d1; width: 10.7879%; height: 24px;"
                    >
                      <div>120,000</div>
                    </td>
                    <td style="width: 10.1818%; height: 24px;">
                      140,000
                    </td>
                    <td
                      style="background-color: #1973d1; width: 10.303%; height: 24px;"
                    >
                      160,000
                    </td>
                    <td style="width: 10.1818%; height: 24px;">
                      180,000
                    </td>
                  </tr>
                  <tr style="height: 24px;">
                    <td style="width: 8.24242%; height: 24px;">
                      A1003
                    </td>
                    <td style="width: 24.6061%; height: 24px;">
                      301&nbsp;– 400
                    </td>
                    <td style="width: 10.7879%; height: 24px;">
                      &nbsp;100,000
                    </td>
                    <td
                      style="background-color: #1973d1; width: 10.1818%; height: 24px;"
                    >
                      120,000
                    </td>
                    <td style="width: 10.303%; height: 24px;">
                      140,000
                    </td>
                    <td
                      style="background-color: #1973d1; width: 10.1818%; height: 24px;"
                    >
                      160,000
                    </td>
                  </tr>
                  <tr style="height: 24px;">
                    <td style="width: 8.24242%; height: 24px;">
                      A1004
                    </td>
                    <td style="width: 24.6061%; height: 24px;">
                      401 – 500
                    </td>
                    <td
                      style="background-color: #1973d1; width: 10.7879%; height: 24px;"
                    >
                      80,000
                    </td>
                    <td style="width: 10.1818%; height: 24px;">
                      100,000
                    </td>
                    <td
                      style="background-color: #1973d1; width: 10.303%; height: 24px;"
                    >
                      120,000
                    </td>
                    <td style="width: 10.1818%; height: 24px;">
                      140,000
                    </td>
                  </tr>
                </tbody>
              </table>
              <h3>
                <span style="color: #0c3baa;"
                  >II. BÁO GIÁ THIẾT KẾ CÔNG TRÌNH KIẾN TRÚC<b
                    >:&nbsp;<b>(VNĐ/m2)</b></b
                  ></span
                >
              </h3>
              <table>
                <tbody>
                  <tr>
                    <td style="width: 8.37542%;" rowspan="2">
                      <strong>MH</strong>
                    </td>
                    <td style="width: 25.0407%;" rowspan="2">
                      <strong>Diện tích sàn(m2)</strong>
                    </td>
                    <td style="width: 42.7124%;" colspan="4">
                      Suất đầu tư dự kiến(triệu đồng)/m2
                    </td>
                    <td style="width: 46.0481%;" rowspan="2">
                      <strong>Ghi chú</strong>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 11.0585%;">
                      &lt;3.5
                    </td>
                    <td style="width: 10.0426%;">
                      3.5 – 4.5
                    </td>
                    <td style="width: 10.7873%;">
                      4.5 – 5.5
                    </td>
                    <td style="width: 10.824%;">
                      &nbsp; &nbsp; &gt;5,5
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 8.37542%;">
                      A2001
                    </td>
                    <td style="width: 25.0407%;">
                      70 – 200
                    </td>
                    <td style="background-color: #1973d1; width: 11.0585%;">
                      160,000
                    </td>
                    <td style="width: 10.0426%;">
                      180,000
                    </td>
                    <td style="background-color: #1973d1; width: 10.7873%;">
                      200,000
                    </td>
                    <td style="width: 10.824%;">
                      220,000
                    </td>
                    <td style="width: 46.0481%;" rowspan="4">
                      Đơn giá thực hiện thiết kế và giao hồ sơ theo quy trình
                      thiết kế Thái Bình Dương.
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 8.37542%;">
                      A2002
                    </td>
                    <td style="width: 25.0407%;">
                      201 –&nbsp;300
                    </td>
                    <td style="width: 11.0585%;">
                      140,000
                    </td>
                    <td style="background-color: #1973d1; width: 10.0426%;">
                      160,000
                    </td>
                    <td style="width: 10.7873%;">
                      180,000
                    </td>
                    <td style="background-color: #1973d1; width: 10.824%;">
                      200,000
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 8.37542%;">
                      A2003
                    </td>
                    <td style="width: 25.0407%;">
                      300 –&nbsp;400
                    </td>
                    <td style="background-color: #1973d1; width: 11.0585%;">
                      120,000
                    </td>
                    <td style="width: 10.0426%;">
                      140,000
                    </td>
                    <td style="background-color: #1973d1; width: 10.7873%;">
                      160,000
                    </td>
                    <td style="width: 10.824%;">
                      180,000
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 8.37542%;">
                      A2004
                    </td>
                    <td style="width: 25.0407%;">
                      401 – 500
                    </td>
                    <td style="width: 11.0585%;">
                      &nbsp;100,000
                    </td>
                    <td style="background-color: #1973d1; width: 10.0426%;">
                      120,000
                    </td>
                    <td style="width: 10.7873%;">
                      140,000
                    </td>
                    <td style="background-color: #1973d1; width: 10.824%;">
                      160,000
                    </td>
                  </tr>
                </tbody>
              </table>
              <h4>
                <strong><i>1.&nbsp;</i>Ghi chú:</strong>
              </h4>
              <p style="text-align: left">
                <em
                  >+<strong> Diện tích</strong>: là diện tích thiết kết, được đo
                  trên mặt bằng không bao gồm phần công trình đối với thiết kế
                  cảnh quan, sân vườn hoặc diện tích sàn đối với thiết kế công
                  trình kiến trúc</em
                ><br />
                <em
                  >+&nbsp;<strong>Suất đầu tư dự kiến</strong>: là giá trị dự
                  kiến đầu tư cho 1m2 sân vườn, sàn&nbsp;với diện tích tương
                  ứng.</em
                ><br />
                <em
                  >+ &nbsp;<strong>Đơn giá thiết kế</strong>: là giá trị giao
                  giữa diện tích và suất đầu tư.</em
                ><br />
                <em
                  >Ví dụ: Một công trình sân vườn có diện tích là 200m2, suất
                  đầu tư 2,5 triệu, đơn giá thiết kế tương ứng là 180,000/m2</em
                ><br />
                <em
                  >Giá của gói dịch vụ thiết kế = 180,000 x 200 = 36,000,000 (36
                  triệu VNĐ)</em
                >
              </p>
              <h4>
                <strong><em>2. Lưu ý</em></strong
                >:
              </h4>
              <p style="text-align:left">
                <span
                  ><em
                    >* Đối với các công trình có diện tích sàn S&lt;70m2, thiết
                    kế phí sẽ được tính thành gói:
                    <strong>15.000.000 đ</strong></em
                  ></span
                ><br />
                <em
                  ><span
                    >* Đối với các công trình có diện tích sàn S&gt;
                    500m2:&nbsp; Liên hệ để được báo giá chi tiết Ưu đãi</span
                  ></em
                ><br />
                <em> <span>* Đơn giá trên chưa bao gồm thuế VAT</span></em>
              </p>
              <h4><strong>3.&nbsp;Các công việc thiết kế:</strong></h4>
              <p style="text-align:left">
                * Khảo sát hiện trạng (áp dụng với diện tích * Thiết kế Concept
                2D)<br />
                * Thiết kế Concept 3D<br />
                * Thiết kế hồ sơ bản vẽ thi công<br />
                * Lập khái toán<br />
                *&nbsp;Giám sát tác giả
              </p>
              <h4><strong>Thời gian thiết kế:</strong></h4>
              <p style="text-align:left">
                <span
                  >* 15 – 20 &nbsp;ngày đối với diện tích thiết kế nhỏ hơn
                  100m2</span
                ><br />
                <span>
                  * 20 – 25 ngày đối với diện tích thiết kế&nbsp;từ 101 đến
                  300m2</span
                ><br />
                <span
                  >* 25 – 30 ngày đối với diện tích thiết kế&nbsp;từ
                  301&nbsp;đến 500m2</span
                ><br />
                <span
                  >* Từ 30 ngày trở lên đối với diện tích thiết kế&nbsp;trên
                  500m2</span
                ><br />
                <span>
                  <em
                    >(Lưu ý:Thời gian thiết kế không bao gồm quá trình thay đổi,
                    sửa chữa thiết kế và ngày lễ tết)</em
                  ></span
                >
              </p>
              <h3>
                <span style="color: #0c3baa;">III. KHUYẾN MÃI:</span>
              </h3>
              <p style="text-align: left;">
                <strong
                  >*Khuyến mãi lên đến<span style="color: #ff0000;">
                    70%
                  </span></strong
                ><strong
                  >phí thiết kế, chi tiết xin
                  <b-link :to="{ name: 'contact' }">Liên hệ</b-link>
                  để được Tư vấn báo giá chi tiết kèm khuyến mãi.</strong
                >
              </p>
              <h3 style="text-align: left;">
                <span style="color: #0c3baa;">IV.&nbsp;CAM KẾT</span>
              </h3>
              <p>
                <span style="font-size: 12pt;"
                  ><strong
                    ><span style="color: #ff0000;"
                      ><em
                        >Thái Bình Dương cam kết hoàn tiền 100% khi khách hàng
                        không hài lòng về phương án thiết kế Concept</em
                      ></span
                    ></strong
                  ></span
                >
              </p>
              <h3 style="text-align: center;">
                <span style="color: #0c3baa;"
                  ><em
                    >Liên hệ với chúng tôi để được tư vấn, lập kế hoạch thiết kế
                  </em></span
                ><span style="color: #0c3baa;"><em>chi tiết </em></span
                ><span style="color: #0c3baa;"
                  ><em
                    >và báo giá chi phí thiết kế cho dự án của bạn !</em
                  ></span
                >
              </h3>
              <p>
                Hãy nhấc máy và liên hệ theo số Hotline:&nbsp;<span
                  style="color: #0c3baa; font-size: 14pt;"
                  ><strong
                    ><a style="color: #0c3baa;" href="tel:0834389222"
                      >0834 389 222</a
                    ></strong
                  ></span
                >
                để được tư vấn và báo giá thiết kế nhanh nhất. Hoặc đăng ký để
                lại thông tin của bạn, Tư vấn Thái Bình Dương sẽ liên hệ tới bạn
                ngay sau khi nhận được yêu cầu.
              </p>
              <p>
                <a href="/contact" class="custombutton"
                  ><span>ĐĂNG KÝ TƯ VẤN</span></a
                >
              </p>
              <p>
                <span style="color: #0c3baa; font-size: 18pt;"
                  ><a style="color: #0c3baa" href="/"
                    ><strong
                      ><em
                        >Thái Bình Dương – Mang lại cuộc sống an nhiên!</em
                      ></strong
                    ></a
                  ></span
                ><img
                  loading="lazy"
                  src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1665974320/bds_tbd/landspace/Kien-truc-xanh-Greenmore1-1024x326_frb0en.webp"
                  alt=""
                  style="margin-top: 15px; width: 100%;"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>`
      },
      {
        id: 2,
        name: "CÔNG TRÌNH CẢNH QUAN",
        bigImage: 'https://res.cloudinary.com/dlzbwakoc/image/upload/v1666208361/bds_tbd/landspace/detail/2/krs_llfxb4.webp',
        content: `<div class="blog-single-detail theme-card">
        <div class="blog-detail-image">
          <img
            loading="lazy"
            src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666208361/bds_tbd/landspace/detail/2/krs_llfxb4.webp"
            class="bg-img img-fluid"
            alt=""
          />
        </div>
        <div class="blog-title">
          <ul class="post-detail">
            <li>13 Tháng 9 2022</li>
            <li>Đăng bởi : Admin</li>
          </ul>
          <h1>Công trình thiết kế cảnh quan</h1>
        </div>
        <div class="details-property">
          <div class="row">
            <div class="col medium-9 small-12 large-9">
              <div class="col-inner">
                <p style="text-align:justify">
                  <strong
                    >Khu Resort cùng cảnh quan vông cùng thơ mộng tại đất nước Balan xinh đẹp. Không gian thoáng đãng cùng khoảng sân vườn được chăm chút cẩn thận giúp khu Resort trở nên vô cùng đẹp khi kết hợp với cảnh quan đồi núi xung quanh.</strong
                  >
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666208411/bds_tbd/landspace/detail/2/krs-1024x683_grnqvc.webp" alt="Khu Resort thơ mộng Enklawa Polany Mountain,&nbsp;Ba Lan">
                <p class="justify-text">
                  Thảm cỏ xanh mát tuyệt đẹp được thiết kế xuyên suốt khu Resort. Các tòa nhà giải trí tại khu Resort được thiết kế theo bố cục phân tán, đặc trưng của các tòa nhà nông thôn châu Âu. 
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666208452/bds_tbd/landspace/detail/2/krs6-1024x683_gcgi0k.webp" alt="Khu Resort thơ mộng Enklawa Polany Mountain,&nbsp;Ba Lan">
                <p class="justify-text">
                  Các yếu tố kiến trúc nhỏ được thiết kế theo phong cách riêng cùng với nơi giải trí và nghỉ ngơi. Không có hàng rào và cây xanh tạo ra một không gian được bao quanh bởi thiên nhiên.
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666208534/bds_tbd/landspace/detail/2/krs3-1024x683_qdv4ql.webp" alt="Các yếu tố kiến trúc nhỏ được thiết kế theo phong cách riêng">
                <h2 class="justify-text">Không gian thoáng đãng kết hợp cảnh quan đồi núi vô cùng thơ mộng tại khu Resort</h2>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666208614/bds_tbd/landspace/detail/2/krs4_p8pwdx.webp" alt="Khung cảnh rộng rãi, cùng không gian mở và được thiên nhiên bao bọc">
                <p class="justify-text">
                  Khung cảnh rộng rãi, cùng không gian mở và được thiên nhiên bao bọc của khu Resort.
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666208663/bds_tbd/landspace/detail/2/krs1-1024x768_zcoydy.webp" alt="Khung cảnh rộng rãi, cùng không gian mở và được thiên nhiên bao bọc">
                <p class="justify-text">
                  Resort cung cấp một cái nhìn toàn cảnh của Dãy núi Tatra với Núi Giewont ngự trị ở phía trước. Vị trí được đầu tư đã trở thành nguồn cảm hứng cho kiến trúc cũng như các giải pháp công năng và không gian.
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666208711/bds_tbd/landspace/detail/2/krs5_isfzle.webp" alt="Khu Resort thơ mộng Enklawa Polany Mountain,&nbsp;Ba Lan">
                <p class="justify-text">
                  Để tạo lên một công trình đặc biệt thể hiện sự độc đáo, cá tính cho riêng mình. Mọi điều đều có thể thực hiện được khi bạn nhận được ý tưởng từ điều gì đấy. Hãy nghĩ tới ý tưởng của mình và việc thực hiện ý tưởng hãy để cho chúng tôi giúp bạn.
                </p>
                <p class="justify-text">
                Hãy liên hệ theo hotline: 0834.389.222 để được tư vấn! Với kinh nghiệm trên 10 năm chăm sóc tận tình, chuyên nghiệp, thiết kế, kiến trúc biệt thự nghỉ dưỡng, thi công cảnh quan.
              </p>
              </div>
            </div>
          </div>
        </div>
      </div>`
      },
      {
        id: 3,
        name: "CÔNG TRÌNH KIẾN TRÚC XANH",
        bigImage: 'https://res.cloudinary.com/dlzbwakoc/image/upload/v1666208774/bds_tbd/landspace/detail/3/tks1-1024x682_utqhee.webp',
        content: `<div class="blog-single-detail theme-card">
        <div class="blog-detail-image">
          <img
            loading="lazy"
            src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666208774/bds_tbd/landspace/detail/3/tks1-1024x682_utqhee.webp"
            class="bg-img img-fluid"
            alt=""
          />
        </div>
        <div class="blog-title">
          <ul class="post-detail">
            <li>13 Tháng 9 2022</li>
            <li>Đăng bởi : Admin</li>
          </ul>
          <h1>Công trình kiến trúc xanh</h1>
        </div>
        <div class="details-property">
          <div class="row">
            <div class="col medium-9 small-12 large-9">
              <div class="col-inner">
                <p style="text-align:justify">
                  <strong
                    >Biệt thự nằm hòa mình với thiên nhiên cùng một khoảng không gian rộng lớn. Với việc được bao bọc bởi thiên nhiên, ngôi biệt thự có thiết kế như sở hữu một cảnh quan sân vườn tự nhiên hòa quyện vào khoảng sân vườn nhân tạo bên trong.</strong
                  >
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666208824/bds_tbd/landspace/detail/3/tks1_gamqn3.webp" alt="Thiết kế sân vườn Biệt thự Avocado tại Santiago, Mexico">
                <p class="justify-text">
                Ngôi biệt thự có một thiết kế độc đáo với một con đường đá chạy xuyên qua biệt thự từ khoảng sân bên ngoài.
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666208452/bds_tbd/landspace/detail/2/krs6-1024x683_gcgi0k.webp" alt="Thiết kế sân vườn Biệt thự Avocado tại Santiago, Mexico">
                <p class="justify-text">
                Những bãi cỏ được làm rất đẹo xung quanh khắp ngôi biệt thự. Kết hợp với chúng là những cây xanh được thiết kế rất tự nhiên. Tuy rất tự nhiên những những cây xanh này vẫn đảm bảo sự hợp lý và cảnh quan cho biệt thự.
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666208893/bds_tbd/landspace/detail/3/tks4_wmpbro.webp" alt="Thiết kế sân vườn Biệt thự Avocado tại Santiago, Mexico">
                <h2 class="justify-text">Không gian thoáng đãng và thiết kế sân vườn kiểu bậc thang mang tính thẩm mỹ cao</h2>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666208960/bds_tbd/landspace/detail/3/tks6_lwymjc.webp" alt="Biệt thự Avocado được thiết kế với khoảng không gian hướng ra sân vườn rất rộng lớn.">
                <p class="justify-text">
                Với tiêu chí thư giãn, tránh xa khỏi khói bụi đô thị và hòa mình vào thiên nhiên. Biệt thự Avocado được thiết kế với khoảng không gian hướng ra sân vườn rất rộng lớn.
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666209010/bds_tbd/landspace/detail/3/tks5_cmi1vw.webp" alt="Biệt thự Avocado được thiết kế với khoảng không gian hướng ra sân vườn rất rộng lớn.">
                <p class="justify-text">
                Sân vườn Biệt thự được thiết kế dạng bậc thang rất thú vị giúp tăng sự hiện đại cũng như phong cách của ngôi biệt thự.
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666209064/bds_tbd/landspace/detail/3/tks7_o47cj2.webp" alt="Biệt thự Avocado được thiết kế với khoảng không gian hướng ra sân vườn rất rộng lớn.">
                <p class="justify-text">
                Để tạo lên một công trình đặc biệt thể hiện sự độc đáo, cá tính cho riêng mình. Mọi điều đều có thể thực hiện được khi bạn nhận được ý tưởng từ điều gì đấy. Hãy nghĩ tới ý tưởng của mình và việc thực hiện ý tưởng hãy để cho chúng tôi giúp bạn.
                </p>
                <p class="justify-text">
                Hãy liên hệ theo hotline: 0834.389.222 để được tư vấn! Với kinh nghiệm trên 10 năm chăm sóc tận tình, chuyên nghiệp, thiết kế, kiến trúc biệt thự nghỉ dưỡng, thi công cảnh quan.
              </p>
              </div>
            </div>
          </div>
        </div>
      </div>`
      },
      {
        id: 4,
        name: "QUY TRÌNH THỰC HIỆN",
        bigImage: 'https://res.cloudinary.com/dlzbwakoc/image/upload/v1666209064/bds_tbd/landspace/detail/3/tks7_o47cj2.webp',
        content: `<div class="blog-single-detail theme-card">
        <div class="blog-detail-image">
          <img
            loading="lazy"
            src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666209064/bds_tbd/landspace/detail/3/tks7_o47cj2.webp"
            class="bg-img img-fluid"
            alt=""
          />
        </div>
        <div class="blog-title">
          <ul class="post-detail">
            <li>13 Tháng 9 2022</li>
            <li>Đăng bởi : Admin</li>
          </ul>
          <h1>Quy trình thi công Thái Bình Dương</h1>
        </div>
        <div class="details-property">
          <div class="row">
            <div class="col medium-9 small-12 large-9">
              <div class="col-inner">
              <p class="justify-text">
              <em>Để đảm bảo chất lượng và tiến độ tốt nhất cho thi công.&nbsp;Thái Bình Dương luôn thực hiện theo đúng quy trình sau: &nbsp;</em>
              </p>
                <ol start="1">
                  <li style="display: list-item">
                    <span style="color: #0c3baa;">
                      <strong> NGHIÊN CỨU HỒ SƠ THI CÔNG (HSTC)</strong>
                    </span>
                  </li>
                  <ul class="flex-column">
                    <li>Nghiên cứu hồ sơ bản vẽ thi công (bản vẽ hình ảnh…)</li>
                    <li>Nghiên cứu biện pháp thi công</li>
                    <li>Nghiên cứu dự toán thi công</li>
                  </ul>
                </ol>
                <ol start="2">
                  <li style="display: list-item">
                    <span style="color: #0c3baa;">
                      <strong> LÊN KẾ HOẠCH THI CÔNG</strong>
                    </span>
                  </li>
                  <ul class="flex-column">
                    <li>Lập bảng kế hoạch, tiến độ thi công</li>
                  </ul>
                </ol>
                <ol start="3">
                  <li style="display: list-item">
                    <span style="color: #0c3baa;">
                      <strong> CHUẨN BỊ THI CÔNG</strong>
                    </span>
                  </li>
                  <ul class="flex-column">
                    <li>Chuẩn bị hồ sơ bản vẽ thi công</li>
                    <li>Chuẩn bị mặt bằng thi công</li>
                    <li>Chuẩn bị nhân lực thi công</li>
                    <li>Chuẩn bị dụng cụ thi công</li>
                    <li>Chuẩn bị vật liệu thi công</li>
                  </ul>
                </ol>
                <ol start="4">
                  <li style="display: list-item">
                    <span style="color: #0c3baa;">
                      <strong> LIÊN HỆ THI CÔNG</strong>
                    </span>
                  </li>
                  <ul class="flex-column">
                    <li>Liên hệ chủ đầu tư</li>
                    <li>Liên hệ đối tác</li>
                  </ul>
                </ol>
                <ol start="5">
                  <li style="display: list-item">
                    <span style="color: #0c3baa;">
                      <strong> TIẾN HÀNH THI CÔNG</strong>
                    </span>
                  </li>
                  <ul class="flex-column">
                    <li>Thi công vườn đứng, vườn treo</li>
                    <li>Thi công cảnh quan, sân vườn</li>
                    <li>Thi công công trình kiến trúc xanh</li>
                  </ul>
                </ol>
                <ol start="6">
                  <li style="display: list-item">
                    <span style="color: #0c3baa;">
                      <strong> HOÀN THÀNH HỒ SƠ</strong>
                    </span>
                  </li>
                  <ul class="flex-column">
                    <li>Nghiệm thu chất lượng vật liệu thi công</li>
                    <li>Nghiệm thu khối lượng hoàn thành</li>
                  </ul>
                </ol>
                <div style="display: flex; justify-content: center;">
                  <img loading="lazy" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666209664/bds_tbd/landspace/detail/4/quy-trinh-thi-cong-greenmore-2020_fdhnii.webp" alt="">
                </div>
                <h3 style="text-align: center;">
                  <span style="font-size: 18pt;">
                      <a href="/landscape-detail/1">
                        <span style="color: #0c3baa; text-transform: uppercase">
                          <em>==&gt; Xem thêm báo giá thi công Thái Bình Dương</em>
                        </span>
                      </a>
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>`
      },
      {
        id: 5,
        name: "SÂN VƯỜN TRÊN MÁI",
        bigImage: 'https://res.cloudinary.com/dlzbwakoc/image/upload/v1666379852/bds_tbd/landspace/detail/5/Anh-7-1024x576_isfbm0.jpg',
        content: `<div class="blog-single-detail theme-card">
        <div class="blog-detail-image">
          <img
            loading="lazy"
            src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666379852/bds_tbd/landspace/detail/5/Anh-7-1024x576_isfbm0.jpg"
            class="bg-img img-fluid"
            alt=""
          />
        </div>
        <div class="blog-title">
          <ul class="post-detail">
            <li>13 Tháng 9 2022</li>
            <li>Đăng bởi : Admin</li>
          </ul>
          <h1>Kiến trúc cảnh quan địa hình – Dạng lập địa bất thường</h1>
        </div>
        <div class="details-property">
          <div class="row">
            <div class="col medium-9 small-12 large-9">
              <div class="col-inner">
                <p style="text-align:justify">
                  <strong
                    >Thiết kế kiến trúc cảnh quan địa hình dựa theo hình dạng của khu đất. Khu đất là một khối đa diện không đều để tối đa hóa việc sử dụng đất.</strong
                  >
                </p>
                <p class="justify-text">
                Chúng tôi đã thêm kiến trúc địa hình trên địa hình hiện có của khu đất. Thiết kế có độ dốc lớn để hài hòa với điều kiện tự nhiên của khu đất.
                </p>
                <img loading="lazy" class="bg-img mb-3" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666379852/bds_tbd/landspace/detail/5/Anh-7-1024x576_isfbm0.jpg" alt="Thiết Kế Kiến Trúc Cảnh Quan Địa Hình">
                <img loading="lazy" class="bg-img mb-3" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666379894/bds_tbd/landspace/detail/5/05-hwa_hun_blooming_house_seoul_korea_iroje_khm_architect-1024x522_wxqts3.jpg" alt="Thiết Kế Kiến Trúc Cảnh Quan Địa Hình">
                <img loading="lazy" class="bg-img mb-3" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666379916/bds_tbd/landspace/detail/5/Anh-3-1024x655_knxitu.jpg" alt="Thiết Kế Kiến Trúc Cảnh Quan Địa Hình">
                <img loading="lazy" class="bg-img mb-3" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666379938/bds_tbd/landspace/detail/5/Anh-4-1024x683_unt4oc.jpg" alt="Thiết Kế Kiến Trúc Cảnh Quan Địa Hình">
                <p class="justify-text">
                Để tiết kiệm tối đa diện tích sân vườn xanh mát, tất cả các không gian ngoại thất của ngôi nhà này đều được bao phủ bởi cây xanh.
                </p>
                <img loading="lazy" class="bg-img mb-3" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666379964/bds_tbd/landspace/detail/5/Anh-6-1024x681_injtjf.jpg" alt="Thiết Kế Kiến Trúc Cảnh Quan Địa Hình">
                <img loading="lazy" class="bg-img mb-3" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666379986/bds_tbd/landspace/detail/5/Anh2-1024x677_tieowy.jpg" alt="Thiết Kế Kiến Trúc Cảnh Quan Địa Hình">
                <p class="justify-text">
                Có nhiều loại vườn giật cấp, vườn trước, vườn nội khu, vườn cầu thang, vườn ngã nước, vườn trên mái. Tất cả các khu vườn thẳng đứng được luân chuyển lẫn nhau liên tục.
                </p>
                <img loading="lazy" class="bg-img mb-3" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666380007/bds_tbd/landspace/detail/5/Anh-1-1024x679_lqfs1g.jpg" alt="Thiết Kế Kiến Trúc Cảnh Quan Địa Hình">
                <img loading="lazy" class="bg-img mb-3" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666380007/bds_tbd/landspace/detail/5/Anh-1-1024x679_lqfs1g.jpg" alt="Thiết Kế Kiến Trúc Cảnh Quan Địa Hình">
                <p class="justify-text">
                Chúng tôi thiết kế kiến trúc cảnh quan thiên nhiên “đồi kiến trúc”, hài hòa với bối cảnh núi non xung quanh và đáp ứng yêu cầu “sống trong thiên nhiên” của chủ nhân.
                </p>
                <p class="justify-text">
                Để tạo lên một công trình cảnh quan thể hiện sự độc đáo, cá tính cho riêng mình. Mọi điều đều có thể thực hiện được khi bạn nhận được ý tưởng từ những thứ xung quanh bạn. Hãy nghĩ tới ý tưởng của mình và việc thực hiện ý tưởng hãy để cho chúng tôi giúp bạn.
                </p>
                <p class="justify-text">
                Hãy liên hệ theo hotline: 0834.389.222 để được tư vấn! Với kinh nghiệm trên 10 năm chăm sóc tận tình, chuyên nghiệp, thiết kế, kiến trúc biệt thự nghỉ dưỡng, thi công cảnh quan.
              </p>
              </div>
            </div>
          </div>
        </div>
      </div>`
      },
      {
        id: 6,
        name: "VƯỜN CHO CHUNG CƯ, TIỂU CẢNH",
        bigImage: 'https://res.cloudinary.com/dlzbwakoc/image/upload/v1666380149/bds_tbd/landspace/detail/6/18-mau-xich-du-san-vuon-dep-2020-12-compressed_zeqr1n.jpg',
        content: `<div class="blog-single-detail theme-card">
        <div class="blog-detail-image">
          <img
            loading="lazy"
            src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666380149/bds_tbd/landspace/detail/6/18-mau-xich-du-san-vuon-dep-2020-12-compressed_zeqr1n.jpg"
            class="bg-img img-fluid"
            alt=""
          />
        </div>
        <div class="blog-title">
          <ul class="post-detail">
            <li>13 Tháng 9 2022</li>
            <li>Đăng bởi : Admin</li>
          </ul>
          <h1>Sưu tầm những mẫu xích đu cho sân vườn đẹp nhất 2022</h1>
        </div>
        <div class="details-property">
          <div class="row">
            <div class="col medium-9 small-12 large-9">
              <div class="col-inner">
                <p style="text-align:justify">
                  <strong
                    >Xích đu trong sân vườn có chức năng làm nơi ngồi nghỉ ngơi thư giãn. Tích hợp một chiếc xích đu nhỏ xinh bên trong sân vườn sẽ giúp bạn có nơi để ngồi nghỉ ngơi thư giãn thưởng thức không khí tuyệt vời từ cây cối, thiên nhiên đem lại tại chính sân vườn của mình. Tham khảo những mẫu xích đu cho sân vườn đẹp nhất 2022 này nhé.</strong
                  >
                </p>
                <h3>Sưu tầm những mẫu xích đu cho sân vườn đẹp nhất 2022</h3>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666380149/bds_tbd/landspace/detail/6/18-mau-xich-du-san-vuon-dep-2020-12-compressed_zeqr1n.jpg" alt="">
                <p class="justify-text">
                Xích đu đem lại cho bạn và gia đình có một không gian vui chơi, thư giãn tuyệt vời bên trong sân vườn của bạn. Ngoài ra, nó còn là một vật dụng giúp trang trí cho sân vườn của bạn với những mẫu rất đẹp.
                </p>
                <img loading="lazy" class="bg-img mb-3" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666380186/bds_tbd/landspace/detail/6/18-mau-xich-du-san-vuon-dep-2020-10-compressed_miv9sp.jpg" alt="">
                <h4>Xích đu ngoài trời</h4>
                <p class="justify-text">
                Những chiếc xích đu trong sân vườn được tạo lên từ những chất liệu phù hợp như gỗ tự nhiên, gỗ nhựa, sắt, inox…
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666380210/bds_tbd/landspace/detail/6/18-mau-xich-du-san-vuon-dep-2020-02-compressed_tvhl9h.jpg" alt="">
                <p class="center-text">
                Mẫu xích đu được làm từ nhôm đúc hoa văn độc đáo bắt mắt này phù hợp cho sân vườn tân cổ điển.
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666380231/bds_tbd/landspace/detail/6/18-mau-xich-du-san-vuon-dep-2020-03-compressed_sbonl4.jpg" alt="">
                <p class="center-text">
                2 mẫu xích đu này được thiết kế như chiếc giường, võng nhỏ. Bạn không chỉ có thể ngồi mà bạn còn có thể nằm trên đó.
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666380251/bds_tbd/landspace/detail/6/18-mau-xich-du-san-vuon-dep-2020-08-compressed_deqznb.jpg" alt="">
                <p class="center-text">
                Xích đu được sắp đặt bên trong pergola, một giàn lam chắn nắng bằng gỗ tự nhiên lớn.
                </p>
                <img loading="lazy" class="bg-img mb-3" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666380278/bds_tbd/landspace/detail/6/18-mau-xich-du-san-vuon-dep-2020-01-compressed_ugsojp.jpg" alt="">
                <h4>Xích đu mây nhựa</h4>
                <p class="justify-text">
                Những chiếc xích đu nhỏ gọn được tạo từ trụ sắt và ghế mây nhựa đẹp mắt. Nó giúp bạn dễ dàng di chuyển chúng đến bất kì vị trí nào trong sân vườn của mình mà bạn muốn.
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666380303/bds_tbd/landspace/detail/6/18-mau-xich-du-san-vuon-dep-2020-09-compressed_bislpk.jpg" alt="">
                <p class="justify-text">
                Kết hợp với chiếc đệm êm ái, bạn đã sở hữu ngay một nơi hoàn hảo có thể ngồi dễ chịu thưởng thức không gian sân vườn nhà mình.
                </p>
                <img loading="lazy" class="bg-img mb-3" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666380322/bds_tbd/landspace/detail/6/18-mau-xich-du-san-vuon-dep-2020-13-compressed_qpuatm.jpg" alt="">
                <h4>Xích đu quả trứng</h4>
                <p class="justify-text">
                Vẫn làm từ trụ sắt cùng với mây nhựa, hình thù của những chiếc xích đu nhỏ gọn này được thiết kế rất đa dạng và bắt mắt như những quả trứng lớn như 2 mẫu trên.
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666380349/bds_tbd/landspace/detail/6/18-mau-xich-du-san-vuon-dep-2020-11-compressed_hi4u3o.jpg" alt="">
                <p class="justify-text">
                Một chiếc xích đu được làm từ 100% là sắt với chiếc ghế vững chắc. Nó đảm bảo cho sự bền bỉ với thời gian bên ngoài trời.
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666380380/bds_tbd/landspace/detail/6/18-mau-xich-du-san-vuon-dep-2020-04-compressed_qrmhcj.jpg" alt="">
                <p class="justify-text">
                Xích đu mây nhựa được thiết kế rất đẹp và bạn có thể đặt ở bất cứ đâu dù ở ban công nhỏ hẹp vì thiết kế nhỏ gọn của nó đem lại.
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666380423/bds_tbd/landspace/detail/6/18-mau-xich-du-san-vuon-dep-2020-05_uegajs.jpg" alt="">
                <p class="justify-text">
                Xích đu ngoài trời này có đệm ghế êm và mái che nắng mưa giúp bạn có thể ngồi ngay cả khi thời tiết xấu.
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666380455/bds_tbd/landspace/detail/6/18-mau-xich-du-san-vuon-dep-2020-06-compressed_hfjang.jpg" alt="">
                <p class="justify-text">
                Chiếc xích đu được thiết kế cấu trúc của một viên kim cương khá độc đáo. Chiếc ghế sofa êm ái giúp trẻ nhỏ thích thú được ngồi lên nó.
                </p>
                <p class="justify-text">
                Để sở hữu một chiếc xích đu khá dễ dàng vì nó có giá không quá cao. Hãy lựa chọn 1 mẫu ưng ý nhất trong các mẫu phía trên để thêm vào sân vườn nhà mình nhé.
                </p>
                <p class="justify-text">
                Hãy liên hệ theo hotline: 0834.389.222 để được tư vấn! Với kinh nghiệm trên 10 năm chăm sóc tận tình, chuyên nghiệp, thiết kế, kiến trúc biệt thự nghỉ dưỡng, thi công cảnh quan.
              </p>
              </div>
            </div>
          </div>
        </div>
      </div>`
      },
      {
        id: 7,
        name: "VƯỜN ĐỨNG, VƯỜN TREO",
        bigImage: 'https://res.cloudinary.com/dlzbwakoc/image/upload/v1666380670/bds_tbd/landspace/detail/7/kthd17_dpbqol.jpg',
        content: `<div class="blog-single-detail theme-card">
        <div class="blog-detail-image">
          <img
            loading="lazy"
            src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666380670/bds_tbd/landspace/detail/7/kthd17_dpbqol.jpg"
            class="bg-img img-fluid"
            alt=""
          />
        </div>
        <div class="blog-title">
          <ul class="post-detail">
            <li>13 Tháng 9 2022</li>
            <li>Đăng bởi : Admin</li>
          </ul>
          <h1>Thiết kế hiện đại của ngôi biệt thự kép tại Kensington, Mỹ</h1>
        </div>
        <div class="details-property">
          <div class="row">
            <div class="col medium-9 small-12 large-9">
              <div class="col-inner">
                <p style="text-align:justify">
                  <strong
                    >Ngôi biệt thự tuyệt đẹp và hết sức độc đáo. Biệt thự được thiết kế theo phong cách hết sức hiện đại. Kết hợp cùng cảnh quan sân vườn hòa vào thiên nhiên, ngôi biệt thự mang lại một ấn tượng sâu sắc với người xem.</strong
                  >
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666380670/bds_tbd/landspace/detail/7/kthd17_dpbqol.jpg" alt="">
                <p class="justify-text">
                Ngôi biệt thự kép là sự thiết kế, kết hợp giữa hai không gian nhà ở riêng biệt. Với lối tư duy thiết kế hiện đại của các kiến trúc sư, sự xa cách giữa hai không gian đã gần như không còn.
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666380706/bds_tbd/landspace/detail/7/kthd9-1_wzszx4.jpg" alt="">
                <p class="justify-text">
                Thiết kế của ngôi biệt thự rât đề cao sự mở rộng của không gian. Cấu trúc các bức tường, lối đi đều giúp bạn có thể phóng tầm mắt ra cảnh quan bên ngoài. Sự thiết kế này mang đến một không gian vừa rộng rãi lại vừa hiện đại cho ngôi biệt thự
                </p>
                <h4>Thiết kế sử dụng kính hiện đại còn làm nổi bật cảnh quan sân vườn của biệt thự</h4>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666380747/bds_tbd/landspace/detail/7/kthd19-1_jsifwi.jpg" alt="">
                <p class="justify-text">
                Căn biệt thự được thiết kế độc đáo với phong cách sử dụng nhiều cửa làm bằng kính. Điều này vừa tạo nên sự hiện đại, vừa tạo ra một phòng triển lãm cảnh quan ở ngay trong ngôi biệt thự.
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666380771/bds_tbd/landspace/detail/7/18-mau-xich-du-san-vuon-dep-2020-03-compressed_hhvan4.jpg" alt="">
                <p class="justify-text">
                Mỗi phòng hướng ra ngoài còn có một bức tường trong suốt toàn phần ở phía cuối. Lối đi trung tâm cũng đều có thể quan sát cảnh quan bên ngoài giúp không gian được mở rộng một cách toàn diện.
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666380810/bds_tbd/landspace/detail/7/kthd18-1_boc9jk.jpg" alt="">
                <p class="justify-text">
                Không gian sân vườn và cảnh quan bên ngoài của biệt thự được bao quanh bởi những cây thân gỗ cùng những thảm cỏ. Thiết kế hiện đại cùng không gian thiên nhiên sân vườn tạo nên sự hài hoà đáng ngạc nhiên.
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666380843/bds_tbd/landspace/detail/7/kthd6-1_gcvd4k.jpg" alt="">
                <p class="justify-text">
                Để tạo lên một công trình đặc biệt thể hiện sự độc đáo, cá tính cho riêng mình. Mọi điều đều có thể thực hiện được khi bạn nhận được ý tưởng từ điều gì đấy. Hãy nghĩ tới ý tưởng của mình và việc thực hiện ý tưởng hãy để cho chúng tôi giúp bạn.
                </p>
                <p class="justify-text">
                Hãy liên hệ theo hotline: 0834.389.222 để được tư vấn! Với kinh nghiệm trên 10 năm chăm sóc tận tình, chuyên nghiệp, thiết kế, kiến trúc biệt thự nghỉ dưỡng, thi công cảnh quan.
              </p>
              </div>
            </div>
          </div>
        </div>
      </div>`
      },
      {
        id: 8,
        name: "HƯỚNG DẪN CHĂM SÓC CÂY XANH",
        bigImage: 'https://res.cloudinary.com/dlzbwakoc/image/upload/v1666381002/bds_tbd/landspace/detail/8/huong-dan-trong-hoa-cuc-van-tho-no-kip-dung-dip-tet-03_kwnmmv.jpg',
        content: `<div class="blog-single-detail theme-card">
        <div class="blog-detail-image">
          <img
            loading="lazy"
            src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666381002/bds_tbd/landspace/detail/8/huong-dan-trong-hoa-cuc-van-tho-no-kip-dung-dip-tet-03_kwnmmv.jpg"
            class="bg-img img-fluid"
            alt=""
          />
        </div>
        <div class="blog-title">
          <ul class="post-detail">
            <li>13 Tháng 9 2022</li>
            <li>Đăng bởi : Admin</li>
          </ul>
          <h1>Cách trồng hoa cúc vạn thọ trang trí sân vườn nở kịp đón tết</h1>
        </div>
        <div class="details-property">
          <div class="row">
            <div class="col medium-9 small-12 large-9">
              <div class="col-inner">
                <p style="text-align:justify">
                  <strong
                    >Cúc Vạn Thọ mang ý nghĩa cầu mong sức khỏe và sự trường thọ cho con người. Hoa cúc vạn thọ xưa phổ biến được trồng trong sân vườn mỗi gia đình. Sau một thời gian bị lãng quên, vài năm trở lại đây, cúc vạn thọ được bày bán nhiều ở chợ hoa tết. Bạn có thể lựa chọn nó để trang trí sân vườn nhà mình vào dịp tết này. Dưới đây là hướng dẫn cách trồng hoa cúc vạn thọ nở kịp đón tết.</strong
                  >
                </p>
                <h2>Cách trồng hoa cúc vạn thọ trang trí sân vườn nở kịp đón tết</h2>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666381002/bds_tbd/landspace/detail/8/huong-dan-trong-hoa-cuc-van-tho-no-kip-dung-dip-tet-03_kwnmmv.jpg" alt="">
                <p class="justify-text">
                Cúc vạn thọ sai hoa và có 2 màu nổi bật là vàng và vàng cam. Cây có thể trồng quanh năm và thời gian hoa nở rất bền và không bị phai màu cánh hoa đến khi tàn. Cúc vạn thọ dễ trồng và dễ ra hoa vì vậy nó sẽ là lựa chọn tuyệt vời dành cho sân vườn của bạn vào dịp tết sắp tới. Nắm được thời gian sinh trưởng của loài hoa này là chìa khóa dẫn đến một sân vườn ngập đầy sắc vàng đón tết đến xuân về cho gia đình bạn.
                </p>
                <h3>Thời điểm trồng hoa vạn thọ kịp nở dịp tết</h3>
                <p class="justify-text">
                Từ thời điểm gieo hạt cho đến khi ra hoa là từ khoảng 60 đến 70 ngày. Có 2 loại giống vạn thọ là cây lùn và cây cao. Cây lùn sinh trưởng nhanh hơn cây cao. Giống vạn thọ lùn khoảng 60 đến 65 ngày cho hoa, còn giống cao là từ 65 đến 70 ngày. Qua đó, bạn đã có thể xác định thời gian gieo hạt cho kế hoạch trồng hoa tết của bạn.
                </p>
                <img loading="lazy" class="bg-img mb-3" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666381038/bds_tbd/landspace/detail/8/huong-dan-trong-hoa-cuc-van-tho-no-kip-dung-dip-tet-01_bgy5qj.jpg" alt="">
                <h3>Chuẩn bị đất trồng</h3>
                <p class="justify-text">
                Cúc vạn thọ cần trồng trong môi trường đất tơi xốp và sạch mầm bệnh. Đất trồng bao gồm đất thịt pha cát trộn xơ dừa và phân chuồng ủ hoai mục để đảm bảo độ tơi xốp thoát nước tốt và giàu dinh dưỡng.
                </p>
                <p class="justify-text">
                Gieo hạt trong những bầu ươm 8 – 10cm để dễ chăm sóc cây con. Sau 15 đến 20 ngày gieo hạt bạn cần chuyển bầu ươm sang khu vực trồng mà bạn cần trang trí trong sân vườn. Chú ý chuyển bầu vào lúc chiều mát và đất trồng mới cần phải tơi xốp. Trong vài ngày đầu chuyển bầu bạn chỉ cần tưới phun sương và sau đó tưới đều đặn ngày 2 lần sáng và chiều tối. Kiểm soát lượng nước tưới và nước mưa không làm úng nước làm cho cây bị thối chết.
                </p>
                <img loading="lazy" class="bg-img mb-3" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666381059/bds_tbd/landspace/detail/8/huong-dan-trong-hoa-cuc-van-tho-no-kip-dung-dip-tet-02_l93b4w.jpg" alt="">
                <h3>Bón phân</h3>
                <p class="justify-text">
                Bón thúc 10 ngày sau khi trồng chuyển bầu bằng phân chuồng hoai mục, sau đó cứ 7 ngày bón 1 lần, tổng cộng bón 4 lần cho vạn thọ đến ngày nở hoa.
                </p>
                <h3>Bấm ngọn cho cây</h3>
                <p class="justify-text">
                Sau 35 ngày gieo hạt thì cây đã có 6-7 cặp lá, đồng thời các chồi nách ở các lá 1,2 và 3 cũng đã chồi lên. Bạn cần bấm ngọn để lại 5 – 6 nách lá vào giai đoạn này để cây không phát triển quá cao và giúp cho các chồi nách phát triển đồng đều để cây ra hoa sai và trải khắp mặt. Thời điểm muộn nhất bạn cần phải bấm ngọn là 5/12 âm lịch với vạn thọ cao và 10/12 âm lịch với vạn thọ lùn.
                </p>
                <p class="justify-text">
                Nếu hoa có khả năng nở sớm hơn dự định, cần hãm tốc độ nở hoa bằng cách tưới thêm phân urê theo tỷ lệ 10gram/ 10 lít nước để tưới, tưới ngày 2 lần.
                </p>
                <p class="justify-text">
                Nếu hoa nở muộn thì ngừng tưới nước 2 ngày cho đến khi thấy lá hơi héo rũ bạn tưới vừa đủ nước ướt mặt đất và những ngày tiếp theo tưới nước pha Nitrat Kali (KNO3) nồng độ theo khuyến cáo nhà sản xuất.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>`
      },
      {
        id: 9,
        name: "SÂN VƯỜN QUÁN CAFE VÀ NHÀ HÀNG",
        bigImage: 'https://res.cloudinary.com/dlzbwakoc/image/upload/v1666381239/bds_tbd/landspace/detail/9/rss-1024x684_e6g6tk.jpg',
        content: `<div class="blog-single-detail theme-card">
        <div class="blog-detail-image">
          <img
            loading="lazy"
            src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666381239/bds_tbd/landspace/detail/9/rss-1024x684_e6g6tk.jpg"
            class="bg-img img-fluid"
            alt=""
          />
        </div>
        <div class="blog-title">
          <ul class="post-detail">
            <li>13 Tháng 9 2022</li>
            <li>Đăng bởi : Admin</li>
          </ul>
          <h1>Resort & Spa với sân vườn tuyệt đẹp Mitsis Rinela Beach</h1>
        </div>
        <div class="details-property">
          <div class="row">
            <div class="col medium-9 small-12 large-9">
              <div class="col-inner">
                <p style="text-align:justify">
                  <strong
                    >Khu Resort & Spa với cảnh quan sân vườn tuyệt vời tại Hy Lạp. Sân vườn với phong cách Hy Lạp kết hợp với cảnh quan ven biển tạo nên một khung cảnh vô cùng đặc sắc cho khu nghỉ dưỡng.</strong
                  >
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666381239/bds_tbd/landspace/detail/9/rss-1024x684_e6g6tk.jpg" alt="">
                <p class="justify-text">
                Các kiến trúc sư tập trung thiết kế khu vực phía trước của các ngôi nhà của khu Resort & Spa. Cụ thể hơn là tạo nên sự kết hợp cảnh quan bãi biển, khu vực ngoài trời chính và quán cà phê.
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666381274/bds_tbd/landspace/detail/9/rss3_lxmfi3.jpg" alt="">
                <p class="justify-text">
                Một đài phun nước được đưa vào giữa tòa nhà trung tâm và bể bơi chính. Đá cẩm thạch được sử dụng trong hồ bơi được đặt ở tất cả các khu vực xung quanh tòa nhà trung tâm như một phương tiện thống nhất và liên tục tự nhiên của các không gian. 
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666381295/bds_tbd/landspace/detail/9/rss18_xrxrmu.jpg" alt="">
                <p class="justify-text">
                Các khu vực sân vườn quanh các phòng của Resort cũng rất được đầu tư. Những thiết kế cảnh quan hấp dẫn được mang tới ngay bên cạnh những du khách.
                </p>
                <img loading="lazy" class="bg-img mb-3" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666381314/bds_tbd/landspace/detail/9/rss2-1024x684_ichemd.jpg" alt="">
                <h4>Thiết kế của Resort & Spa được giữ đơn giản và được làm nổi bật bởi các yếu tố cảnh quan theo phong cách Hy Lạp</h4>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666381336/bds_tbd/landspace/detail/9/rss9_ppjl7n.jpg" alt="">
                <p class="justify-text">
                Sân vườn thiết kế theo phong cách Hy Lạp tạo nên một cảnh quan rất đẹp và tự nhiên.
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666381492/bds_tbd/landspace/detail/9/rss17_plntmw.jpg" alt="">
                <p class="justify-text">
                Ban công và mọi khoảng không gian cảnh quan của Resort & Spa đều được thiết kế rất chi tiết.
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666381583/bds_tbd/landspace/detail/9/rss7_ujhtwj.jpg" alt="">
                <p class="justify-text">
                Để tạo lên một công trình đặc biệt thể hiện sự độc đáo, cá tính cho riêng mình. Mọi điều đều có thể thực hiện được khi bạn nhận được ý tưởng từ điều gì đấy. Hãy nghĩ tới ý tưởng của mình và việc thực hiện ý tưởng hãy để cho chúng tôi giúp bạn.
                </p>
                <p class="justify-text">
                Hãy liên hệ theo hotline: 0834.389.222 để được tư vấn! Với kinh nghiệm trên 10 năm chăm sóc tận tình, chuyên nghiệp, thiết kế, kiến trúc biệt thự nghỉ dưỡng, thi công cảnh quan.
              </p>
              </div>
            </div>
          </div>
        </div>
      </div>`
      },
      {
        id: 10,
        name: "KIẾN TRÚC VĂN PHÒNG, THƯƠNG MẠI XANH",
        bigImage: 'https://res.cloudinary.com/dlzbwakoc/image/upload/v1666381647/bds_tbd/landspace/detail/10/Toa-nha-xanh-tu-nhien-Singapore-Pavilion-cho-Expo-2020-Dubai-07_qgvsui.jpg',
        content: `<div class="blog-single-detail theme-card">
        <div class="blog-detail-image">
          <img
            loading="lazy"
            src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666381647/bds_tbd/landspace/detail/10/Toa-nha-xanh-tu-nhien-Singapore-Pavilion-cho-Expo-2020-Dubai-07_qgvsui.jpg"
            class="bg-img img-fluid"
            alt=""
          />
        </div>
        <div class="blog-title">
          <ul class="post-detail">
            <li>13 Tháng 9 2022</li>
            <li>Đăng bởi : Admin</li>
          </ul>
          <h1>Toà văn phòng có cây xanh phủ kín sử dụng robot công nghệ chăm sóc</h1>
        </div>
        <div class="details-property">
          <div class="row">
            <div class="col medium-9 small-12 large-9">
              <div class="col-inner">
                <p style="text-align:justify">
                  <strong
                    >The Singapore Pavilion for Expo 2020 Dubai minh hoạ khát vọng của thành phố hướng tới một tương lai bền vững kết hợp kiến trúc, thiên nhiên, công nghệ và văn hóa. Với tiêu đề “Nature.Nurture.Future”, toà nhà được thiết kế bởi WOHA Architects và thiết kế cảnh quan Salad Dressing giới thiệu một mẫu môi trường đô thị tương lai của Singapore, là hình ảnh thu nhỏ của Thành phố trong Tự nhiên.</strong
                  >
                </p>
                <h2>Toà văn phòng có cây xanh phủ kín sử dụng robot công nghệ chăm sóc</h2>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666381647/bds_tbd/landspace/detail/10/Toa-nha-xanh-tu-nhien-Singapore-Pavilion-cho-Expo-2020-Dubai-07_qgvsui.jpg" alt="">
                <p class="justify-text">
                Toà nhà là lời giới thiệu một mẫu môi trường đô thị tương lai của Singapore, là hình ảnh thu nhỏ của Thành phố trong Tự nhiên. Không gian xanh đa tầng tạo ra một hệ sinh thái tự cung tự cấp làm nổi bật những ý tưởng về tính bền vững và khả năng phục hồi thông qua sự kết hợp giữa công nghệ và thiên nhiên.
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666381675/bds_tbd/landspace/detail/10/Toa-nha-xanh-tu-nhien-Singapore-Pavilion-cho-Expo-2020-Dubai-01_yswmil.jpg" alt="">
                <p class="justify-text">
                Sự đóng góp này cho Expo Dubai nói lên sự tập trung của Singapore trong việc nuôi dưỡng một tương lai bền vững, thể hiện khả năng hòa nhập thiên nhiên trong môi trường đô thị.
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666381768/bds_tbd/landspace/detail/10/Toa-nha-xanh-tu-nhien-Singapore-Pavilion-cho-Expo-2020-Dubai-02_khdjsj.jpg" alt="">
                <p class="justify-text">
                Với hơn 170 loại thực vật từ các môi trường sống đa dạng ở Singapore và được đặt trong các lớp cây xanh khác nhau, từ vườn treo đến tường thẳng đứng, gian hàng tạo ra một không gian xanh ba chiều, đưa du khách đi qua các khu rừng mưa nhiệt đới, khu vực ngập mặn và suối rừng.
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666381790/bds_tbd/landspace/detail/10/Toa-nha-xanh-tu-nhien-Singapore-Pavilion-cho-Expo-2020-Dubai-03_e1xj4g.jpg" alt="">
                <p class="justify-text">
                Nước uống được sản xuất thông qua quy trình khử muối bằng năng lượng mặt trời tại chỗ được sử dụng để tưới nhỏ giọt, giảm thiểu lượng nước tiêu thụ.
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666381811/bds_tbd/landspace/detail/10/Toa-nha-xanh-tu-nhien-Singapore-Pavilion-cho-Expo-2020-Dubai-04_alunmv.jpg" alt="">
                <p class="justify-text">
                Để thực hiện chăm sóc cây xanh, toà nhà đã sử dụng ba robot leo núi nguyên mẫu đi xuyên qua các bức tường xanh, kiểm tra sức khỏe của cây và thu thập dữ liệu môi trường điện tử. Được phát triển bởi công ty khởi nghiệp Oceania Robotics có trụ sở tại Singapore, các robot đại diện cho một giải pháp khả thi để duy trì các khu vườn thẳng đứng và trên cao, rất phổ biến trong kiến trúc đương đại của Singapore.
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666381836/bds_tbd/landspace/detail/10/Toa-nha-xanh-tu-nhien-Singapore-Pavilion-cho-Expo-2020-Dubai-06_ec5bgy.jpg" alt="">
                <p class="justify-text">
                Toà văn phòng xanh sẽ có các trải nghiệm đa phương tiện khác nhau mô tả những thách thức về môi trường và cách tiếp cận của Singapore để giải quyết chúng.
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666381857/bds_tbd/landspace/detail/10/Toa-nha-xanh-tu-nhien-Singapore-Pavilion-cho-Expo-2020-Dubai-05_ioun2q.jpg" alt="">
                <p class="justify-text">
                Chương trình của sự kiện bao gồm các cuộc nói chuyện khám phá tương lai của các thành phố, các cuộc triển lãm kỹ thuật số, cũng như các buổi chiếu phim và trình diễn ánh sáng. Một loạt các hoạt động được lựa chọn sẽ có sẵn trực tuyến, cho phép những người không thể đến Expo để trải nghiệm dự án ảo.
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666381897/bds_tbd/landspace/detail/10/Toa-nha-xanh-tu-nhien-Singapore-Pavilion-cho-Expo-2020-Dubai-08_eskmma.jpg" alt="">
                <p class="justify-text">
                Expo 2020 Dubai là một chương trình quy tụ một loạt các đóng góp quốc gia, các tổ chức giáo dục và doanh nghiệp khám phá chủ đề “Kết nối Tâm trí, Tạo dựng Tương lai”, tập trung mạnh mẽ vào tính bền vững.
                </p>
                <img loading="lazy" class="bg-img" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666381920/bds_tbd/landspace/detail/10/Toa-nha-xanh-tu-nhien-Singapore-Pavilion-cho-Expo-2020-Dubai-09_wps30g.jpg" alt="">
                <p class="justify-text">
                Hãy liên hệ theo hotline: 0834.389.222 để được tư vấn! Với kinh nghiệm trên 10 năm chăm sóc tận tình, chuyên nghiệp, thiết kế, kiến trúc biệt thự nghỉ dưỡng, thi công cảnh quan.
              </p>
              </div>
            </div>
          </div>
        </div>
      </div>`
      },
      {
        id: 11,
        name: "QUY TRÌNH THIẾT KẾ",
        bigImage: 'https://res.cloudinary.com/dlzbwakoc/image/upload/v1666209064/bds_tbd/landspace/detail/3/tks7_o47cj2.webp',
        content: `<div class="blog-single-detail theme-card">
        <div class="blog-detail-image">
          <img
            loading="lazy"
            src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666209064/bds_tbd/landspace/detail/3/tks7_o47cj2.webp"
            class="bg-img img-fluid"
            alt=""
          />
        </div>
        <div class="blog-title">
          <ul class="post-detail">
            <li>13 Tháng 9 2022</li>
            <li>Đăng bởi : Admin</li>
          </ul>
          <h1>Quy trình thiết kế Thái Bình Dương</h1>
        </div>
        <div class="details-property">
          <div class="row">
            <div class="col medium-9 small-12 large-9">
              <div class="col-inner">
              <p class="justify-text">
              <em>Quy trình thiết kế Thái Bình Dương là một chuỗi các bước. Mỗi bước trong quy trình giúp công trình mới của bạn có được chất lượng và tiến độ tốt nhất. Vì vậy, Thái Bình Dương luôn thực hiện theo đúng quy trình như sau:  </em>
              </p>
                <ol start="1">
                  <li style="display: list-item">
                    <span style="color: #0c3baa;">
                      <strong>LẬP NHIỆM VỤ THIẾT KẾ</strong>
                    </span>
                  </li>
                  <ul class="flex-column">
                    <li>Khảo sát hiện trạng</li>
                    <li>Điều tra số liệu, thu thập thông tin</li>
                    <li>Lấy ý kiến khách hàng</li>
                    <li>Báo giá thiết kế</li>
                  </ul>
                </ol>
                <ol start="2">
                  <li style="display: list-item">
                    <span style="color: #0c3baa;">
                      <strong>HỢP ĐỒNG THIẾT KẾ</strong>
                    </span>
                  </li>
                  <ul class="flex-column">
                    <li>Hai bên thỏa thuận thống nhất hợp đồng</li>
                    <li>Ký hợp đồng</li>
                  </ul>
                </ol>
                <ol start="3">
                  <li style="display: list-item">
                    <span style="color: #0c3baa;">
                      <strong>LÊN KẾ HOẠCH THIẾT KẾ</strong>
                    </span>
                  </li>
                  <ul class="flex-column">
                    <li>Lập bảng kế hoạch, tiến độ thiết kế</li>
                  </ul>
                </ol>
                <ol start="4">
                  <li style="display: list-item">
                    <span style="color: #0c3baa;">
                      <strong>THIẾT KẾ PHƯƠNG ÁN</strong>
                    </span>
                  </li>
                  <ul class="flex-column">
                    <li>Thiết kế ý tưởng (Concept 2D) sơ bộ mặt bằng</li>
                    <li>Thiết kế phối cảnh (Concept 3D) và hoàn thiện hồ sơ phương án chốt</li>
                  </ul>
                </ol>
                <ol start="5">
                  <li style="display: list-item">
                    <span style="color: #0c3baa;">
                      <strong>TRIỂN KHAI HỒ SƠ</strong>
                    </span>
                  </li>
                  <ul class="flex-column">
                    <li>Thiết kế hồ sơ kỹ thuật thi công</li>
                    <li>Dự toán thi công</li>
                  </ul>
                </ol>
                <ol start="6">
                  <li style="display: list-item">
                    <span style="color: #0c3baa;">
                      <strong>HOÀN THÀNH HỒ SƠ</strong>
                    </span>
                  </li>
                  <ul class="flex-column">
                    <li>Bàn giao hồ sơ</li>
                    <li>Hai bên ký hợp đồng thanh quyết toán</li>
                  </ul>
                </ol>
                <div style="display: flex; justify-content: center;">
                  <img loading="lazy" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666381975/bds_tbd/landspace/detail/11/quy-trinh-thiet-ke-greenmore-2020_bdjsdm.jpg" alt="">
                </div>
                <h3 style="text-align: center;">
                  <span style="font-size: 18pt;">
                      <a href="/landscape-detail/1">
                        <span style="color: #0c3baa; text-transform: uppercase">
                          <em>==&gt; Xem thêm báo giá thi công Thái Bình Dương</em>
                        </span>
                      </a>
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>`
      },
      {
        id: 12,
        name: "QUY TRÌNH BẢO DƯỠNG",
        bigImage: 'https://res.cloudinary.com/dlzbwakoc/image/upload/v1666209064/bds_tbd/landspace/detail/3/tks7_o47cj2.webp',
        content: `<div class="blog-single-detail theme-card">
        <div class="blog-detail-image">
          <img
            loading="lazy"
            src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666209064/bds_tbd/landspace/detail/3/tks7_o47cj2.webp"
            class="bg-img img-fluid"
            alt=""
          />
        </div>
        <div class="blog-title">
          <ul class="post-detail">
            <li>13 Tháng 9 2022</li>
            <li>Đăng bởi : Admin</li>
          </ul>
          <h1>Quy trình bảo dưỡng Thái Bình Dương</h1>
        </div>
        <div class="details-property">
          <div class="row">
            <div class="col medium-9 small-12 large-9">
              <div class="col-inner">
              <p class="justify-text">
              <em>Để đảm bảo việc chăm sóc khu vườn được đúng kỹ thuật và phát triển tốt nhất. Thái Bình Dương luôn thực hiện theo đúng quy trình như sau:</em>
              </p>
                <ol start="1">
                  <li style="display: list-item">
                    <span style="color: #0c3baa;">
                      <strong>KHẢO SÁT HIỆN TRẠNG</strong>
                    </span>
                  </li>
                  <ul class="flex-column">
                    <li>Nghiên cứu chủng loại cây trong vườn</li>
                    <li>Nghiên cứu tình hình sâu bệnh và biện pháp bảo dưỡng</li>
                    <li>Báo giá bảo dưỡng</li>
                  </ul>
                </ol>
                <ol start="2">
                  <li style="display: list-item">
                    <span style="color: #0c3baa;">
                      <strong>HỢP ĐỒNG BẢO DƯỠNG</strong>
                    </span>
                  </li>
                  <ul class="flex-column">
                    <li>Hai bên thỏa thuận thống nhất hợp đồng</li>
                    <li>Ký hợp đồng</li>
                  </ul>
                </ol>
                <ol start="3">
                  <li style="display: list-item">
                    <span style="color: #0c3baa;">
                      <strong>LÊN KẾ HOẠCH BẢO DƯỠNG</strong>
                    </span>
                  </li>
                  <ul class="flex-column">
                    <li>Lập bảng kế hoạch tiến độ bảo dưỡng</li>
                  </ul>
                </ol>
                <ol start="4">
                  <li style="display: list-item">
                    <span style="color: #0c3baa;">
                      <strong>THỰC HIỆN BẢO DƯỠNG</strong>
                    </span>
                  </li>
                  <ul class="flex-column">
                    <li>Kiểm tra xử lý hệ thống cấp, thu nước tưới cây công trình (thông tắc xử lý tưới đều, xử lý dò dỉ máng, làm sạch hệ thống tưới)</li>
                    <li>Cắt tỉa cây nghệ thuật</li>
                    <li>Kiểm tra sâu bệnh của cây và phun thuốc diệt sâu bệnh (nếu có)</li>
                    <li>Bổ sung chất dinh dưỡng, bón phân thích hợp cho từng loại cây</li>
                    <li>Vệ sinh, dọn dẹp vườn</li>
                  </ul>
                </ol>
                <ol start="5">
                  <li style="display: list-item">
                    <span style="color: #0c3baa;">
                      <strong>NGHIỆM THU BẢO DƯỠNG</strong>
                    </span>
                  </li>
                  <ul class="flex-column">
                    <li>Nghiệm thu chất lượng và khối lượng hoàn thành</li>
                  </ul>
                </ol>
                <div style="display: flex; justify-content: center;">
                  <img loading="lazy" src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1666382028/bds_tbd/landspace/detail/12/quy-trinh-baoduong-greenmore-2020_ovtqn0.jpg" alt="">
                </div>
                <h3 style="text-align: center;">
                  <span style="font-size: 18pt;">
                      <a href="/landscape-detail/1">
                        <span style="color: #0c3baa; text-transform: uppercase">
                          <em>==&gt; Xem thêm báo giá thi công Thái Bình Dương</em>
                        </span>
                      </a>
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>`
      },
    ],
  },
  getters: {
    sliceRealEstateNews: (e) =>
      e.news.realEstateNews.filter((e) => e.image).slice(0, 6),
    sliceLandscapeDesignNews: (e) =>
      e.news.landscapeDesignNews.filter((e) => e.image).slice(0, 6),
    footerRealEstateNews: (e) => e.news.realEstateNews[0],
    footerLandscapeDesignNews: (e) => e.news.landscapeDesignNews[0],
    getRealEstateNews: (e) => e.news.realEstateNews,
    getLandscapeDesignNews: (e) => e.news.landscapeDesignNews,
    getRealEstateNewsLength: (e) => e.news.realEstateNews.length,
    getLandscapeDesignNewsLength: (e) => e.news.landscapeDesignNews.length,
    getAllNews: (e) => e.news.realEstateNews.concat(e.news.landscapeDesignNews),
    getAllNewsLength: (e) =>
      e.news.realEstateNews.length + e.news.landscapeDesignNews.length,
    sliceRealEstateNewsOnSale: (e) =>
      e.news.realEstateNews.filter((e) => e.image).slice(0, 3),
    getPropertyById: (e) => (a) => e.properties.find((e) => e.id === a),
    getLandscapePackagesById: (e) => (a) => e.landscapePackages.find((e) => e.id === a),
  },
  mutations: {
    setNews(e, a) {
      e.news = a;
    },
    setLoading(e) {
      e.isLoading = !e.isLoading;
    },
    setNav(e, a) {
      e.isNavbarOpen = a;
    },
  },
  actions: {
    async getNews({ commit: e }) {
      try {
        let a = await s.get("https://tbd-real-estate.herokuapp.com/v1/news");
        e("setNews", a.data.news), e("setLoading");
      } catch (t) {
        console.error(t), e("setLoading");
      }
    },
  },
  modules: {},
});
