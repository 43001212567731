<template>
  <section class="provide-service bg-light">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="title-1">
            <span class="label label-gradient color-6">{{
              $t("About us")
            }}</span>
            <h2>{{ $t("About real estate") }}</h2>
            <hr />
          </div>
        </div>
        <div class="col-md-12 col-xl-5">
          <div class="provide-details pb-4">
            <h5
              v-for="(introduce, index) in introduces"
              :key="index"
              class="font-roboto provide-details-front"
            >
              <b-img
                style="width: 25px;margin-bottom: 3px;height: 25px;"
                src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1664110084/bds_tbd/icons/rhombus_dsahdz.svg"
                alt="origin-image"
              />
              {{ $t(introduce) }}
            </h5>
          </div>
        </div>
        <div class="col-md-12 col-xl-6 offset-lg-1 order-lg-1">
          <div class="service-img">
            <img
              :src="`${publicImagePath}main/Artboard-1_2x.webp`"
              class="img-fluid"
              style="border-radius: 10px"
              alt="origin-image"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { BImg } from "bootstrap-vue";
export default {
  components: {
    BImg,
  },
  data() {
    return {
      introduces: [
        "Pacific Real Estate is a unit specializing in providing information about service land, resort real estate in Hanoi and Hoa Binh areas. With the working motto Prestige - Dedication - Professionalism, Dat Vang Real Estate has been and will become a reliable partner of investors and customers!",
        "With a team of professional, dedicated and competent consultants in the field of consulting, we will support the purchase and sale procedures, help customers optimize time and costs in the process of finding out. and transaction. Pacific Real Estate is proud to have become one of the resort real estate consulting units, service land with diverse products to meet the needs of the market. We are always appreciated for our capacity and business reputation.",
      ],
    };
  },
  computed: {
    publicImagePath() {
      return this.$store.state.publicImagePath;
    },
  },
};
</script>

<style scoped>
h5 {
  font-size: 1.25rem;
}
</style>
