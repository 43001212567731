<template>
  <header class="header-4">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="menu">
            <div class="brand-logo">
              <b-link :to="{ name: 'home' }">
                <b-img
                  src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1663848746/bds_tbd/Da_DUYET_tf6u2y.svg"
                  class="img-fluid for-light"
                  alt="brand-logo"
                />
              </b-link>
            </div>
            <div class="menu-right-item">
              <nav style="display: flex; align-items: center;">
                <div class="main-navbar">
                  <div id="mainnav">
                    <div
                      class="toggle-nav"
                      @click="isNavbarOpen = !isNavbarOpen"
                    >
                      <b-img
                        width="18px"
                        height="18px"
                        src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1663998337/bds_tbd/icons/icons8-menu_kltm1m.svg"
                        alt="icon"
                      />
                    </div>
                    <ul class="nav-menu" :class="{ open: isNavbarOpen }">
                      <li
                        class="back-btn"
                        @click="isNavbarOpen = !isNavbarOpen"
                      >
                        <div class="mobile-back text-end">
                          {{ $t("Back") }}
                        </div>
                      </li>
                      <li class="dropdown">
                        <b-link
                          @click="
                            isNavbarOpen = !isNavbarOpen;
                            isDisplayDropdown = !isDisplayDropdown;
                          "
                          :to="{ name: 'home' }"
                          class="nav-link menu-title"
                          >{{ $t("Home") }}</b-link
                        >
                      </li>
                      <li
                        class="dropdown"
                        @mouseover="isDisplayDropdown2 = true"
                        @mouseout="isDisplayDropdown2 = false"
                        style="cursor: pointer;"
                      >
                        <a
                          @click="
                            isDisplayDropdown = !isDisplayDropdown;
                            isDisplayDropdown2 = false;
                          "
                          class="nav-link menu-title"
                          >{{ $t("Field")
                          }}<span class="according-menu">
                            <b-img
                              width="16px"
                              height="16px"
                              style="transition: 0.5s"
                              :style="{
                                transform: isDisplayDropdown
                                  ? 'rotate(0deg)'
                                  : 'rotate(180deg)',
                              }"
                              src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1664277549/bds_tbd/icons/down-arrow-svgrepo-com-1_brocqh.svg"
                              alt="according-menu"
                            />
                          </span>
                        </a>
                        <ul
                          class="nav-submenu slide"
                          :style="{
                            height:
                              isDisplayDropdown || isDisplayDropdown2
                                ? '100px'
                                : '0px',
                          }"
                        >
                          <li
                            v-for="(field, index) in fields"
                            :key="index"
                            style="display: flex; align-items: center; cursor: pointer;"
                          >
                            <b-link
                              @click="
                                isNavbarOpen = !isNavbarOpen;
                                isDisplayDropdown = !isDisplayDropdown;
                              "
                              :to="{ name: field.link }"
                              >{{ $t(field.name) }}
                            </b-link>
                          </li>
                        </ul>
                      </li>
                      <li class="dropdown">
                        <b-link
                          @click="isNavbarOpen = !isNavbarOpen"
                          :to="{ name: 'news-list' }"
                          class="nav-link menu-title"
                          >{{ $t("News") }}</b-link
                        >
                      </li>
                      <li class="dropdown">
                        <b-link
                          @click="
                            isNavbarOpen = !isNavbarOpen;
                            isDisplayDropdown = !isDisplayDropdown;
                          "
                          :to="{ name: 'contact' }"
                          class="nav-link menu-title"
                          >{{ $t("Contact") }}</b-link
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
              <ul
                class="header-right"
                style="display: flex; align-items: center;"
              >
                <li class="right-menu color-6">
                  <ul
                    class="nav-menu"
                    @click="isHoveringLanguage = !isHoveringLanguage"
                    @mouseover="isHoveringLanguage = true"
                    @mouseout="isHoveringLanguage = false"
                  >
                    <li class="dropdown">
                      <a href="#/">
                        <b-img
                          width="18px"
                          height="18x"
                          src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1663997028/bds_tbd/icons/global-9348_p4trk6.svg"
                          alt="global"
                        />
                      </a>
                      <ul
                        class="nav-submenu"
                        :class="{ open: isHoveringLanguage }"
                      >
                        <li
                          v-for="language in locales"
                          :key="language.id"
                          style="width: 180px; display: flex; align-items:center; margin-left: 18px;"
                        >
                          <img
                            :src="language.flag"
                            style="width: 24px; height: 18px; margin-right: 5px;"
                            alt=""
                          />
                          <a
                            style="cursor: pointer"
                            @click="
                              $i18n.locale = language.code;
                              changeTitle();
                            "
                            >{{ language.label }}</a
                          >
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { BLink, BImg } from "bootstrap-vue";
export default {
  components: {
    BLink,
    BImg,
  },
  data() {
    return {
      isHoveringLanguage: false,
      // isNavbarOpen: false,
      isDisplayDropdown: false,
      isDisplayDropdown2: false,
      isLanguageOpen: false,
      fields: [
        { name: "Landscape design", link: "landscape-design" },
        { name: "Real estate", link: "real-estate-sale" },
      ],
      locales: [
        {
          id: 1,
          code: "vi",
          label: "Tiếng Việt",
          flag:
            "https://res.cloudinary.com/dlzbwakoc/image/upload/v1663869727/bds_tbd/vietnam_crkn5u.svg",
        },
        {
          id: 2,
          code: "en",
          label: "English",
          flag:
            "https://res.cloudinary.com/dlzbwakoc/image/upload/v1663869851/bds_tbd/united-states-flag-icon_ppmntk.svg",
        },
      ],
    };
  },
  methods: {
    changeTitle() {
      document.title = this.$t(
        "Pacific Group | Consulting real estate investment in suburban, resort & industrial zones"
      );
    },
  },
  computed: {
    isNavbarOpen: {
      get() {
        return this.$store.state.isNavbarOpen;
      },
      set(newValue) {
        return this.$store.commit("setNav", newValue);
      },
    },
  },
};
</script>

<style scoped>
.menu-right-item {
  display: flex;
}
header .menu {
  justify-content: space-between;
  margin-right: 21px;
}

.slide {
  clear: both;
  /* width:100%; */
  /* height:0px; */
  overflow: hidden;
  text-align: center;
  transition: height 0.6s ease;
}
</style>
