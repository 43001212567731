<template>
  <section class="video-section">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-5 col-lg-8">
          <div class="video-details">
            <div class="title-1">
            <span class="label label-gradient color-6">{{$t('Pacific Group')}}</span>
            <h2>{{$t('Mission and responsibilities')}}</h2>
            <hr />
          </div>
            <p class="font-roboto">
              <b-img
                class="icon-description"
                alt="icon"
                width="32px"
                height="32px"
                src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1664110084/bds_tbd/icons/rhombus_dsahdz.svg"
              />
              {{$t('Pacific Group creates an environment for each individual to always be dynamic and creative in the process of working, as well as support together to achieve the best results in building and developing individuals and teams. That is the core interest of the Pacific itself.')}}
            </p>
          </div>
        </div>
        <div class="col-xl-6 col-lg-4">
          <div class="play-icon">
            <div class="icon-video">
              <span class="heart-animation"></span>
              <a @click="isModalOpen = !isModalOpen; playVideo()">
                <b-img
                  width="18px"
                  height="18px"
                  src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1664293156/bds_tbd/icons/play_1_ssocy4_qwhmk3.svg"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade video-modal"
      id="video"
      :class="{ show: isModalOpen }"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <button
              type="button"
              class="btn-close"
              @click="isModalOpen = !isModalOpen; closeVideo()"
            >
              <span aria-hidden="true">×</span>
            </button>
            <iframe
              title="realestate"
              src=""
              allowfullscreen
              loading="lazy"
              allow="encrypted-media"
              autoplay="false"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { BImg } from "bootstrap-vue";
export default {
  components: {
    BImg,
  },
  data() {
    return {
      isModalOpen: false,
      iFrameUrl: 'https://res.cloudinary.com/dlzbwakoc/video/upload/v1665302732/bds_tbd/others/tbd_ibqwhh.mp4'
    };
  },
  methods: {
    closeVideo(){
      var iframeTag = document.querySelector("iframe");
      iframeTag.src = "";
    },
    playVideo(){
      var iframeTag = document.querySelector("iframe");
      iframeTag.src = this.iFrameUrl;
    }
  }
};
</script>

<style scoped>
.show {
  display: block;
  padding-left: 0px;
}
</style>
