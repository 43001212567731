<template>
  <div>
    <GetInTouchSection />
  </div>
</template>

<script>
import GetInTouchSection from "../components/contact/GetInTouchSection.vue";
export default {
  components: {
    GetInTouchSection
  },
};
</script>

<style></style>
