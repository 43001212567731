<template>
  <div>
    <HomeSection />
    <OurServiceSection />
    <FeatureSection />
    <VideoSection />
    <MyGallery />
    <Brand2 />
    <BuyOrSell />
    <NewsSection :newsData="realEstateNews" />
  </div>
</template>

<script>
import HomeSection from "../components/home/HomeSection.vue";
import OurServiceSection from "../components/home/OurServiceSection.vue";
import MyGallery from "../components/home/MyGallery.vue";
import FeatureSection from "../components/home/FeatureSection.vue";
import Brand2 from "../components/home/Brand2.vue";
import NewsSection from "../components/home/NewsSection.vue";
import VideoSection from "../components/home/VideoSection.vue";
import BuyOrSell from "../components/home/BuyOrSell.vue";
export default {
  components: {
    HomeSection,
    FeatureSection,
    Brand2,
    MyGallery,
    OurServiceSection,
    NewsSection,
    VideoSection,
    BuyOrSell
  },
  computed: {
    realEstateNews() {
      return this.$store.getters.sliceRealEstateNews;
    },
    landscapeDesignNews() {
      return this.$store.getters.sliceLandscapeDesignNews;
    },
  },
};
</script>
