<template>
  <section class="small-section get-in-touch">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 contact-img">
          <img
            src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1663845832/sheltos/inner-pages/Email-campaign-amico_rrdjav.webp"
            class="img-fluid"
            alt=""
          />
        </div>
        <div class="col-lg-6">
          <div class="log-in">
            <div class="mb-4 text-start">
              <h2>{{ $t("Contact us") }}</h2>
            </div>
            <form class="row gx-3" @submit.prevent="sendEmail">
              <input
                    type="hidden"
                    name="to_name"
                    value="Thai Binh Duong Group"
                  />
              <div class="form-group col-md-12">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <img
                          src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1663955367/bds_tbd/icons/reshot-icon-user-ZXFJAEQURK_qy5pq6.svg"
                          style="width:16px; height:16px; margin-right:5px"
                          alt=""
                        />
                    </div>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('Enter your name')"
                    required=""
                    name="from_name"
                    v-model="name"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="form-group col-md-6">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <img
                          src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1663955502/bds_tbd/icons/phone-3114_lgk1np.svg"
                          style="width:16px; height:16px; margin-right:5px"
                          alt=""
                        />
                    </div>
                  </div>
                  <input
                    :placeholder="$t('phone number')"
                    class="form-control"
                    name="from_phone"
                    type="tel"
                    maxlength="10"
                    required=""
                    v-model="phone"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="form-group col-md-6">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <img
                          src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1663955625/bds_tbd/icons/reshot-icon-email-UERZ83AW2P_eqyulz.svg"
                          style="width:16px; height:16px; margin-right:5px"
                          alt=""
                        />
                    </div>
                  </div>
                  <input
                    type="email"
                    class="form-control"
                    :placeholder="$t('email address')"
                    name="from_email"
                    required=""
                    v-model="email"
                    autocomplete="off"
                    aria-label="Email"
                  />
                </div>
              </div>
              <div class="form-group col-md-12">
                <textarea
                  class="form-control"
                  rows="6"
                  name="message"
                  :placeholder="$t('Write here something')"
                  v-model="message"
                >
                </textarea>
              </div>
              <div class="col-md-12 submit-btn">
                <button class="btn btn-gradient color-6 btn-pill" type="submit">
                  {{ $t("Send Your Message") }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import emailjs from "emailjs-com";
export default {
  data() {
    return {
      name: "",
      phone: "",
      email: "",
      message: "",
      vnf_regex: /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/,
    };
  },
  methods: {
    sendEmail(e) {
      if (this.isPhoneNumberValid) {
        try {
          emailjs.sendForm(
            "service_g20e2g4",
            "template_rh4b94g",
            e.target,
            "hFcomuyTwO1f58S2b",
            {}
          );
          // Reset form field
          this.name = "";
          this.phone = "";
          this.email = "";
          this.message = "";
          this.$toast.success("Send contact success!");
        } catch (error) {
          console.log({ error });
          this.$toast.error("Send contact failed!");
        }
      } else{
        this.$toast.error("Invalid phone number!");
      }
    },
  },
  computed: {
    isPhoneNumberValid() {
      return this.vnf_regex.test(this.phone);
    },
  },
};
</script>

<style></style>
