<template>
  <div>
    <!-- Slide banner -->
    <SlideBanner />
    <!-- Blog bài viết bằng tay -->
    <BlogSection />
    <!-- Sản phẩm -->
    <PropertiesSection />
    <!-- Bài viết tự động -->
    <NewsSection />
  </div>
</template>

<script>
import SlideBanner from "../components/real-estate/SlideBanner.vue";
import BlogSection from "../components/real-estate/BlogSection.vue";
import NewsSection from "../components/real-estate/NewsSection.vue";
import PropertiesSection from "../components/real-estate/PropertiesSection.vue";

export default {
  components: {
    SlideBanner,
    BlogSection,
    NewsSection,
    PropertiesSection,
  },
};
</script>
