<template>
  <section class="provide-service bg-light">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="title-1">
            <span class="label label-gradient color-6">{{ $t("About") }}</span>
            <h2>{{ $t("About landscape design") }}</h2>
            <hr />
          </div>
        </div>
        <div class="col-md-12 col-xl-6">
          <div class="provide-details pb-4">
            <h5
              v-for="(introduce, index) in introduces"
              :key="index"
              class="font-roboto provide-details-front"
            >
              <b-img
                style="width: 25px;margin-bottom: 3px;height: 25px;"
                src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1664110084/bds_tbd/icons/rhombus_dsahdz.svg"
                alt="origin-image"
              />
              {{ $t(introduce) }}
            </h5>
          </div>
        </div>
        <div class="col-md-12 col-xl-6">
          <div class="service-img">
            <img
              :src="`${publicImagePath}others/041195102848479.5f3fc2d8914f1.webp`"
              class="img-fluid"
              style="border-radius: 10px"
              alt="origin-image"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { BImg } from "bootstrap-vue";
export default {
  components: {
    BImg,
  },
  data() {
    return {
      introduces: [
        `Pacific landscape design draws inspiration from nature, people and architectural art to bring natural and peaceful living spaces to everyone. Therefore, Pacific Ocean focuses on the field of green architecture, specializing in the design and construction of landscape, garden and architectural works in the style of Green Architecture.`,
        "Pacific Ocean understands that to do something well, all resources must be focused on it. Therefore, during its operation, Pacific Ocean trains its staff, optimizes working processes, researches new systems, etc., in order to bring quality works to customers in the field. this.",
      ],
    };
  },
  computed: {
    publicImagePath() {
      return this.$store.state.publicImagePath;
    },
  },
};
</script>

<style scoped>
h5 {
  font-size: 1.25rem;
}
</style>
