<template>
  <footer>
    <div class="footer footer-bg">
      <div class="container">
        <div class="row">
          <div class="col-xl-4">
            <div class="footer-links footer-details">
              <div class="footer-content">
                <b-link :to="{ name: 'home' }">
                  <b-img
                    src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1663848746/bds_tbd/Da_DUYET_tf6u2y.svg"
                    alt="logo-footer"
                    class="logo-footer"
                  />
                </b-link>
                <p style="font-weight: 600">
                  <b-img
                    class="icon-description"
                    width="32px"
                    height="32px"
                    src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1664110084/bds_tbd/icons/rhombus_dsahdz.svg"
                    alt=""
                  />
                  {{
                    $t(
                      "Come to us, customers will experience the best service, the most perfect with the most competitive price. Customers can rest assured with our 24/7 service."
                    )
                  }}
                </p>
                <div class="footer-contact">
                  <ul>
                    <li>
                      <b-img
                        class="icon-description"
                        width="32px"
                        height="32px"
                        src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1664110084/bds_tbd/icons/rhombus_dsahdz.svg"
                        alt=""
                      />
                      {{
                        $t(
                          "House number 5, lane 257, Thanh Nhan street, Thanh Nhan ward, Hai Ba Trung district, Hanoi city, Vietnam"
                        )
                      }}
                    </li>
                    <li style="margin-right: 10px">
                      <b-img
                        class="icon-description"
                        width="32px"
                        height="32px"
                        src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1664110084/bds_tbd/icons/rhombus_dsahdz.svg"
                        alt=""
                      />
                      Telephone: 0834 389 222
                    </li>
                    <li>
                      <b-img
                        class="icon-description"
                        width="32px"
                        height="32px"
                        src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1664110084/bds_tbd/icons/rhombus_dsahdz.svg"
                        alt=""
                      />
                      Email: duongnt.godland@gmail.com
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-8">
            <div class="row">
              <div class="col-lg-6 col-md-7">
                <div class="footer-links footer-left-space">
                  <h5
                    class="footer-title"
                    @click="isDisplayNavDropdown = !isDisplayNavDropdown"
                  >
                    {{ $t("ABOUT THE PACIFIC GROUP") }}
                  </h5>
                  <ul
                    class="footer-content"
                    :style="{
                      display: isDisplayNavDropdown ? 'block' : 'none',
                    }"
                  >
                    <li v-for="(item, index) in linkWebs" :key="index">
                      <b-link :to="{ name: item.link }">
                        <b-img
                          class="icon-description"
                          width="32px"
                          height="32px"
                          src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1664110084/bds_tbd/icons/rhombus_dsahdz.svg"
                          alt=""
                        />
                        {{ $t(item.name) }}</b-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="footer-links">
                  <h5 class="footer-title">
                    {{ $t("COMPANIONSHIP &CONNECT") }}
                  </h5>
                  <div class="footer-content">
                    <p class="mb-0">
                      <b-img
                        class="icon-description"
                        width="32px"
                        height="32px"
                        src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1664110084/bds_tbd/icons/rhombus_dsahdz.svg"
                        alt=""
                      />
                      {{
                        $t(
                          "We accompany investors and customers from buying and selling land to consulting and operating design to increase the value of BDS. Please contact us."
                        )
                      }}
                    </p>
                    <form>
                      <div class="input-group">
                        <input
                          type="email"
                          class="form-control"
                          placeholder="Email Address"
                          required
                        />
                        <span class="input-group-apend">
                          <button
                            type="submit"
                            class="input-group-text"
                            id="basic-addon2"
                            aria-label="Subscribe"
                          >
                            <b-img
                              class="icon-description"
                              width="32px"
                              height="32px"
                              src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1664047128/bds_tbd/icons/paper-plane-svgrepo-com_1_jnpagw.svg"
                              alt=""
                            />
                          </button>
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="bottom-blog">
                <div
                  class="media col-sm-12 col-md-6 p-2 "
                  v-if="landscapeDesignNews"
                >
                  <a :href="landscapeDesignNews.url">
                    <div class="img-overlay">
                      <img
                        :src="landscapeDesignNews.image.thumbnail.contentUrl"
                        alt="landscapeDesignNews"
                      />
                    </div>
                  </a>
                  <div class="media-body">
                    <h6>
                      <a :href="landscapeDesignNews.url" class="module line-clamp line-clamp-2">{{
                        landscapeDesignNews.name
                      }}</a>
                    </h6>
                    <p class="font-roboto">
                      <a :href="landscapeDesignNews.url" class="module line-clamp line-clamp-3">{{
                        landscapeDesignNews.description
                      }}</a>
                    </p>
                  </div>
                </div>
                <div
                  class="media col-sm-12 col-md-6 p-2 "
                  v-if="realEstateNews"
                >
                  <a :href="realEstateNews.url">
                    <div class="img-overlay">
                      <img
                        :src="realEstateNews.image.thumbnail.contentUrl"
                        alt="realEstateNews"
                      />
                    </div>
                  </a>
                  <div class="media-body">
                    <h6>
                      <a :href="realEstateNews.url" class="module line-clamp line-clamp-2">{{
                        realEstateNews.name
                      }}</a>
                    </h6>
                    <p class="font-roboto">
                      <a :href="realEstateNews.url" class="module line-clamp line-clamp-3">{{
                        realEstateNews.description
                      }}</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sub-footer footer-light">
      <div class="container">
        <div class="row">
          <div class="col-xl-12 col-md-12 text-center">
            <div class="copy-right">
              <p class="mb-0">
                © 2022 -
                {{ $t("Pacific Technology And Trading Company Limited") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { BLink, BImg } from "bootstrap-vue";
export default {
  components: {
    BLink,
    BImg,
  },
  data() {
    return {
      linkWebs: [
        { name: "News", link: "news-list" },
        { name: "Landscape design", link: "landscape-design" },
        { name: "Real estate", link: "real-estate-sale" },
        { name: "Contact us", link: "contact" },
      ],
      isDisplaySocialDropdown: true,
      isDisplayNavDropdown: true,
    };
  },
  created() {
    window.addEventListener("resize", this.displayMenu);
  },
  destroyed() {
    window.removeEventListener("resize", this.displayMenu);
  },
  methods: {
    displayMenu() {
      if (window.screen.width > 500) {
        this.isDisplayNavDropdown = true;
      }
    },
  },
  computed: {
    landscapeDesignNews() {
      return this.$store.getters.footerLandscapeDesignNews;
    },
    realEstateNews() {
      return this.$store.getters.footerRealEstateNews;
    },
  },
};
</script>

<style>
</style>
