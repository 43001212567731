<template>
  <section class="property-section">
    <div class="container">
      <div class="row ratio_55">
        <div class="col">
          <div class="title-1">
            <span class="label label-gradient color-6">{{ $t("News") }}</span>
            <h2>{{ $t("Latest News") }}</h2>
            <hr />
          </div>
          <div class="property-2 row column-space color-6">
            <div
              v-for="(news, index) in newsData"
              :key="index"
              class="col-xl-4 col-md-6"
            >
              <div class="property-box">
                <div class="property-image">
                  <div class="property-slider color-6">
                    <a :href="news.url" target="_blank">
                      <b-img-lazy
                        v-if="news.image"
                        :src="news.image.contentUrl"
                        class="bg-img"
                        height="200px"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div class="property-details">
                  <a :href="news.url" target="_blank" class="module line-clamp line-clamp-2" style="height: 50px">
                    <h3>{{ news.name }}</h3>
                  </a>
                  <h6 class="color-6 module line-clamp line-clamp-1">{{ news.provider[0].name }} - {{ formattedDate(news.datePublished) }}</h6>
                  <div class="module line-clamp line-clamp-3" style="height: 90px">
                    <p >
                    {{ news.description }}
                  </p>
                  </div>
                  <div class="property-btn">
                    <a :href="news.url" target="_blank" class="btn btn-gradient color-6 btn-pill">{{$t('See More')}}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { BImgLazy } from "bootstrap-vue";
export default {
  components: {
    BImgLazy
  },
  props: {
    newsData: Array,
  },
  methods: {
    formattedDate(dateData) {
      var t = dateData.split(/[- : T]/);
      const result = new Date(
        t[0],
        t[1] - 1,
        t[2],
        t[3] || 0,
        t[4] || 0
      ).toLocaleString([], {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        hour12: false,
        minute: '2-digit',
      });
      return result;
    },
  },
  computed: {
    realEstateNews() {
      return this.$store.getters.sliceRealEstateNews;
    },
  },
};
</script>

<style scoped>
.bg-img {
  width: 100%;
  object-fit: cover;
}
</style>
