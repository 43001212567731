<template>
  <section class="small-section">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="title-1">
            <span class="label label-gradient color-6">{{
              $t("Companions")
            }}</span>
            <h2>{{ $t("Cooperation and development companion") }}</h2>
            <hr />
          </div>
          <VueSlickCarousel
            :slidesToShow="5"
            :arrows="false"
            :responsive="responsive"
            :autoplay="true"
            :dots="false"
            :infinite="true"
            :slidesToScroll="1"
            class="slide-1 brand-slider"
          >
            <div v-for="(brand, index) in brands" :key="index">
              <div class="logo-box">
                <b-img-lazy
                  :style="{height: (brand == 'techcombank.svg') ? '100px' : 'auto'}"
                  :src="`${publicImagePath}logos/${brand}`"
                  alt="cooperation_unit_logo"
                  class="img-fluid img-slick-carousel"
                />
              </div>
            </div>
          </VueSlickCarousel>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { BImgLazy } from "bootstrap-vue";
export default {
  components: {
    VueSlickCarousel,
    BImgLazy,
  },
  data() {
    return {
      brands: [
        "logo_clb-180x-01_pszmsd.webp",
        "logoinvest_nwe25f.webp",
        "logoBNI-01_jtpx8o.webp",
        "Smarttech_uomsu5.webp",
        "logo_trang_tien_ns5dig.webp",
        "mb-bank-logo-inkythuatso-01-10-09-01-10-removebg-preview_hvi9rd.webp",
        "logo_BRG_Group_mpjrct.webp",
        "logo-hai-dang-group-2794-removebg-preview_b2lxs0.webp",
        "logo_hongha_jlhxrl.webp",
        "logo3mien-01_vmzgep.webp",
        "Logo-Sacombank-new.webp",
        "techcombank.svg",
      ],
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 481,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    };
  },
  computed: {
    publicImagePath() {
      return this.$store.state.publicImagePath;
    },
  },
};
</script>

<style></style>
