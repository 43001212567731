<template>
  <div v-if="isLoading" class="loader-wrapper">
    <div class="row loader-text">
      <div class="col-12">
        <img
          :src="`${publicImagePath}others/ezgif-5-c19be966ee_gbvpfn.webp`"
          class="img-fluid"
        />
      </div>
      <div class="col-12">
        <div>
          <h3 class="mb-0">{{ $t("Loading") }}...</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed:{
    isLoading(){
      return this.$store.state.isLoading;
    },
    publicImagePath(){
      return this.$store.state.publicImagePath;
    }
  }
};
</script>

<style></style>
