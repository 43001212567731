<template>
  <div class="col-md-12">
    <div class="blog-wrap">
      <div class="blog-image">
        <b-img-lazy
          v-if="news.image"
          class="bg-img img-fluid"
          :src="news.image.contentUrl"
          alt=""
        />
        <b-img-lazy
          v-if="!news.image"
          src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1664186700/bds_tbd/others/tkcq_jpzcnt.webp"
          class="bg-img img-fluid"
          alt=""
        />
      </div>
      <div class="blog-details">
        <div>
          <span>
            <b-img
              class="icon-description"
              width="32px"
              height="32px"
              src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1664110084/bds_tbd/icons/rhombus_dsahdz.svg"
              alt=""
            />
            Tác giả & nguồn: {{ news.provider[0].name }}
          </span>
          <p>{{ formattedDate(news.datePublished) }}</p>
          <h3>
            <a :href="news.url" target="_blank">{{ news.name }}</a>
          </h3>
          <p class="font-roboto">
            {{ news.description }}
          </p>
          <a :href="news.url" target="_blank">Đọc thêm</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BImg, BImgLazy } from "bootstrap-vue";
export default {
  components: {
    BImg,
    BImgLazy
  },
  props: {
    news: Object,
  },
  methods: {
    formattedDate(dateData) {
      var t = dateData.split(/[- : T]/);
      const result = new Date(
        t[0],
        t[1] - 1,
        t[2],
        t[3] || 0,
        t[4] || 0
      ).toLocaleString([], {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        hour12: false,
        minute: "2-digit",
      });
      return result;
    },
  },
};
</script>

<style scoped>
.bg-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}
</style>
