<template>
  <div class="col-xl-4 col-lg-4">
    <div class="sticky-cls blog-sidebar blog-right">
      <div class="filter-cards">
        <div class="advance-card">
          <div class="category-property">
            <ul>
              <li v-for="(category, index) in categories" :key="index">
                <p class="root-category" style="color: #ffffff">
                  {{ category.rootCategory }}
                </p>
                <ul class="nav flex-column ms-1">
                  <li
                    v-for="subCategory in category.subCategory"
                    :key="subCategory.id"
                  >
                    <b-link
                      :to="{
                        name: 'landscape-detail',
                        params: { id: subCategory.id },
                      }"
                      class="sub-category"
                    >
                      {{ subCategory.name }}
                    </b-link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BLink } from "bootstrap-vue";
export default {
  components: {
    BLink,
  },
  data() {
    return {
      categories: [
        {
          rootCategory: "SÂN VƯỜN, TIỂU CẢNH",
          subCategory: [
            { id: 1, name: "Sân vườn cho biệt thự và nhà ở" },
            { id: 5, name: "Sân vườn trên mái" },
            { id: 6, name: "Vườn cho chung cư, tiểu cảnh" },
            { id: 7, name: "Vườn đứng, vườn treo" },
            { id: 8, name: "Hướng dẫn chăm sóc cây xanh" },
            { id: 9, name: "Vật liệu và kỹ thuật thi công" },
          ],
        },
        {
          rootCategory: "CÔNG TRÌNH CẢNH QUAN",
          subCategory: [
            { id: 2, name: "Cảnh quan sinh thái, nghỉ dưỡng" },
            { id: 3, name: "Cảnh quan công trình công cộng" },
            { id: 2, name: "Cảnh quan di tích, danh thắng" },
            { id: 9, name: "Sân vườn quán cafe và nhà hàng" },
          ],
        },
        {
          rootCategory: "CÔNG TRÌNH KIẾN TRÚC XANH",
          subCategory: [
            { id: 5, name: "Công trình kiến trúc biệt thự, nhà ở" },
            { id: 3, name: "Kiến trúc nhà hàng, khách sạn xanh" },
            { id: 2, name: "Kiến trúc nhà ở, chung cư xanh" },
            { id: 10, name: "Kiến trúc văn phòng, thương mại xanh" },
          ],
        },
        {
          rootCategory: "QUY TRÌNH THỰC HIỆN",
          subCategory: [
            { id: 11, name: "Quy trình thiết kế Thái Bình Dương" },
            { id: 4, name: "Quy trình thi công Thái Bình Dương" },
            { id: 12, name: "Quy trình bảo dưỡng Thái Bình Dương" },
          ],
        },
      ],
    };
  },
  computed: {
    rightIconImagePath() {
      return (
        this.$store.state.publicImagePath +
        "icons/8665195_chevron_right_icon_dge29m.svg"
      );
    },
  },
};
</script>

<style scoped>
.root-category {
  background: #0c3baa;
  font-weight: 700;
  font-size: 15px;
  border-radius: 0 2rem 2rem 0;
  padding: 10px 20px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.sub-category {
  padding-left: 30px;
  background-image: url(https://res.cloudinary.com/dlzbwakoc/image/upload/v1666030932/bds_tbd/icons/right-arrow-2_dlwqgr.svg);
  background-position: 5px 4px;
  background-repeat: no-repeat;
  margin-bottom: 5px;
  background-size: 16px 16px;

  font-size: 16px;
  font-weight: bold;
  display: block;
  color: #333333;
}

.sub-category:hover {
  background-image: url(https://res.cloudinary.com/dlzbwakoc/image/upload/v1666030932/bds_tbd/icons/right-arrow_oqdvb0.svg);
  background-color: #0c3baa;
  color: #ffffff !important;
  background-size: 16px 16px;
}
</style>
