<template>
  <section class="blog-section bg-comman-2">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="title-2 text-white">
            <h2>{{$t('Latest News')}}</h2>
            <p>
              {{$t('Watch the latest news about landscape design')}}
            </p>
          </div>
          <div class="blog-1">
            <Swiper :options="swiperOptions">
              <SwiperSlide
                v-for="(news, index) in landscapeDesignNews"
                :key="index"
                class="blog-box"
              >
                <div class="img-box">
                  <b-img :src="news.image.contentUrl" alt="" class="img-fluid" />
                </div>
                <div class="blog-content">
                  <span>{{ news.provider[0].name }} - {{ formattedDate(news.datePublished) }}</span>
                  <h3>
                    <a :href="news.url" target="_blank">
                      {{ news.name }}
                    </a>
                  </h3>
                  <p class="font-roboto">
                    {{ news.description }}
                  </p>
                  <a
                    :href="news.url" target="_blank"
                    class="btn btn-gradient btn-pill color-2 btn-lg"
                    >{{$t('See More')}}</a
                  >
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { BImg } from "bootstrap-vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  components: {
    Swiper,
    SwiperSlide,
    BImg,
  },
  data() {
    return {
      swiperOptions: {
        loop: true,
        spaceBetween: 30,
        slidesPerView: 1,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
        },
        breakpoints:{
          1024: {
            slidesPerView: 2
          }
        }
      },
    };
  },
  methods: {
    formattedDate(dateData) {
      var t = dateData.split(/[- : T]/);
      const result = new Date(
        t[0],
        t[1] - 1,
        t[2],
        t[3] || 0,
        t[4] || 0
      ).toLocaleString([], {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        hour12: false,
        minute: '2-digit',
      });
      return result;
    },
  },
  computed: {
    landscapeDesignNews() {
      return this.$store.getters.sliceLandscapeDesignNews;
    },
  },
};
</script>

<style scoped>
@media (max-width: 767.98px) {
  .blog-1 .blog-box {
    display: block;
    padding: 0;
  }

  .blog-1 .blog-box .img-box img, .blog-1 .blog-box .img-box {
    width: 100%;
  }

  .blog-1 .blog-box .img-box img{
    max-width: 100%;
  }

  .blog-1 .blog-box .img-box {
    margin-top: 0;
  }

}
</style>
