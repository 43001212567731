<template>
  <div class="tap-top color-6" :class="{ 'top': isOpen > 600 }" @click="gotoTop">
    <div>
      <b-img
        width="16px"
        height="16px"
        src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1664101063/bds_tbd/icons/up-chevron-svgrepo-com_1_gcbavo.svg"
        alt="go-to-top"
      />
    </div>
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue';
export default {
  components: {
    BImg
  },
  data() {
    return {
      isOpen: 0,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.isOpen = document.documentElement.scrollTop;
    },
    gotoTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style></style>
