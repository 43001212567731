<template>
  <section class="coming-simple h-auto">
    <div class="container">
      <div class="row log-in">
        <div class="col-lg-6 ratio2_3 order-lg-1">
          <b-img
            src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1664036899/sheltos/inner-pages/Wavy_Ppl-11_Single-08_sxdpdt.webp"
            class="img-fluid bg-size"
            alt=""
          />
        </div>
        <div class="col-lg-6 m-0">
          <div class="title-3 text-start">
            <h2>{{$t('Coming soon')}}</h2>
          </div>
          <div class="site-construction">
            <h4>{{$t('Site under construction, we are currently working')}}</h4>
          </div>
          <b-link
            :to="{ name: 'home' }"
            class="btn btn-gradient color-2 btn-pill mt-4"
          >
            <b-img
              width="18px"
              height="18x"
              style="margin-bottom: 3px;"
              src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1664037463/bds_tbd/icons/left-arrow-svgrepo-com_1_aypjcu.svg"
              alt=""
            />
            {{$t('Back to home')}}</b-link
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { BLink, BImg } from "bootstrap-vue";
export default {
  components: {
    BLink,
    BImg,
  },
};
</script>

<style></style>
