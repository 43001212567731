var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"header-4"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"menu"},[_c('div',{staticClass:"brand-logo"},[_c('b-link',{attrs:{"to":{ name: 'home' }}},[_c('b-img',{staticClass:"img-fluid for-light",attrs:{"src":"https://res.cloudinary.com/dlzbwakoc/image/upload/v1663848746/bds_tbd/Da_DUYET_tf6u2y.svg","alt":"brand-logo"}})],1)],1),_c('div',{staticClass:"menu-right-item"},[_c('nav',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"main-navbar"},[_c('div',{attrs:{"id":"mainnav"}},[_c('div',{staticClass:"toggle-nav",on:{"click":function($event){_vm.isNavbarOpen = !_vm.isNavbarOpen}}},[_c('b-img',{attrs:{"width":"18px","height":"18px","src":"https://res.cloudinary.com/dlzbwakoc/image/upload/v1663998337/bds_tbd/icons/icons8-menu_kltm1m.svg","alt":"icon"}})],1),_c('ul',{staticClass:"nav-menu",class:{ open: _vm.isNavbarOpen }},[_c('li',{staticClass:"back-btn",on:{"click":function($event){_vm.isNavbarOpen = !_vm.isNavbarOpen}}},[_c('div',{staticClass:"mobile-back text-end"},[_vm._v(" "+_vm._s(_vm.$t("Back"))+" ")])]),_c('li',{staticClass:"dropdown"},[_c('b-link',{staticClass:"nav-link menu-title",attrs:{"to":{ name: 'home' }},on:{"click":function($event){_vm.isNavbarOpen = !_vm.isNavbarOpen;
                          _vm.isDisplayDropdown = !_vm.isDisplayDropdown;}}},[_vm._v(_vm._s(_vm.$t("Home")))])],1),_c('li',{staticClass:"dropdown",staticStyle:{"cursor":"pointer"},on:{"mouseover":function($event){_vm.isDisplayDropdown2 = true},"mouseout":function($event){_vm.isDisplayDropdown2 = false}}},[_c('a',{staticClass:"nav-link menu-title",on:{"click":function($event){_vm.isDisplayDropdown = !_vm.isDisplayDropdown;
                          _vm.isDisplayDropdown2 = false;}}},[_vm._v(_vm._s(_vm.$t("Field"))),_c('span',{staticClass:"according-menu"},[_c('b-img',{staticStyle:{"transition":"0.5s"},style:({
                              transform: _vm.isDisplayDropdown
                                ? 'rotate(0deg)'
                                : 'rotate(180deg)',
                            }),attrs:{"width":"16px","height":"16px","src":"https://res.cloudinary.com/dlzbwakoc/image/upload/v1664277549/bds_tbd/icons/down-arrow-svgrepo-com-1_brocqh.svg","alt":"according-menu"}})],1)]),_c('ul',{staticClass:"nav-submenu slide",style:({
                          height:
                            _vm.isDisplayDropdown || _vm.isDisplayDropdown2
                              ? '100px'
                              : '0px',
                        })},_vm._l((_vm.fields),function(field,index){return _c('li',{key:index,staticStyle:{"display":"flex","align-items":"center","cursor":"pointer"}},[_c('b-link',{attrs:{"to":{ name: field.link }},on:{"click":function($event){_vm.isNavbarOpen = !_vm.isNavbarOpen;
                              _vm.isDisplayDropdown = !_vm.isDisplayDropdown;}}},[_vm._v(_vm._s(_vm.$t(field.name))+" ")])],1)}),0)]),_c('li',{staticClass:"dropdown"},[_c('b-link',{staticClass:"nav-link menu-title",attrs:{"to":{ name: 'news-list' }},on:{"click":function($event){_vm.isNavbarOpen = !_vm.isNavbarOpen}}},[_vm._v(_vm._s(_vm.$t("News")))])],1),_c('li',{staticClass:"dropdown"},[_c('b-link',{staticClass:"nav-link menu-title",attrs:{"to":{ name: 'contact' }},on:{"click":function($event){_vm.isNavbarOpen = !_vm.isNavbarOpen;
                          _vm.isDisplayDropdown = !_vm.isDisplayDropdown;}}},[_vm._v(_vm._s(_vm.$t("Contact")))])],1)])])])]),_c('ul',{staticClass:"header-right",staticStyle:{"display":"flex","align-items":"center"}},[_c('li',{staticClass:"right-menu color-6"},[_c('ul',{staticClass:"nav-menu",on:{"click":function($event){_vm.isHoveringLanguage = !_vm.isHoveringLanguage},"mouseover":function($event){_vm.isHoveringLanguage = true},"mouseout":function($event){_vm.isHoveringLanguage = false}}},[_c('li',{staticClass:"dropdown"},[_c('a',{attrs:{"href":"#/"}},[_c('b-img',{attrs:{"width":"18px","height":"18x","src":"https://res.cloudinary.com/dlzbwakoc/image/upload/v1663997028/bds_tbd/icons/global-9348_p4trk6.svg","alt":"global"}})],1),_c('ul',{staticClass:"nav-submenu",class:{ open: _vm.isHoveringLanguage }},_vm._l((_vm.locales),function(language){return _c('li',{key:language.id,staticStyle:{"width":"180px","display":"flex","align-items":"center","margin-left":"18px"}},[_c('img',{staticStyle:{"width":"24px","height":"18px","margin-right":"5px"},attrs:{"src":language.flag,"alt":""}}),_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.$i18n.locale = language.code;
                            _vm.changeTitle();}}},[_vm._v(_vm._s(language.label))])])}),0)])])])])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }