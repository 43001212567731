<template>
  <section class="home-section layout-1 layout-9">
    <div class="container">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 col-lg-12">
          <div class="home-main arrow-light">
            <div class="box-header">
              <div>
                <b-img
                  src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1664360235/bds_tbd/icons/Pacific-01_a4czmc.svg"
                  class="img-fluid signature-image"
                  alt=""
                />
                <div class="looking-icons">
                  <h5 style="color: #ffffff">
                    {{ $t("What are you looking for?") }}
                  </h5>
                  <ul>
                    <li v-for="(item, index) in lookingIcons" :key="index">
                      <b-link :to="{ name: item.url }" class="looking-icon">
                        <img
                          :src="item.image"
                          class="property-svg"
                          alt=""
                        />
                        <h6>{{ item.name }}</h6>
                      </b-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue';
export default {
  components: {
    BLink,
    BImg
  },
  data(){
    return{
      lookingIcons:[
        { name: 'Resort Real Estate', image: 'https://res.cloudinary.com/dlzbwakoc/image/upload/v1664555860/bds_tbd/icons/resort_1_y09ov1.svg', url: 'real-estate-sale' },
        { name: 'Landscape Design', image: 'https://res.cloudinary.com/dlzbwakoc/image/upload/v1664556185/bds_tbd/icons/drawing_1_s4hu9n.svg', url: 'landscape-design' },
        { name: 'Technology', image: 'https://res.cloudinary.com/dlzbwakoc/image/upload/v1664556554/bds_tbd/icons/future_1_mwooux.svg', url: '/#' },
      ]
    }
  }
};
</script>

<style scoped>
.property-svg {
  width: 50px;
  height: 50px;
}

.signature-image {
  width: 40%;
}

@media (max-width: 575px) {
  .signature-image {
    width: 100%;
  }
}

a{
  color: #0c3baa;
}
</style>
