<template>
  <section class="property-section">
    <div class="container">
      <div class="row ratio_55">
        <div class="col">
          <div class="title-1">
            <span class="label label-gradient color-1">{{$t('Sales')}}</span>
            <h2>{{$t('Landscape design packages')}}</h2>
            <hr />
          </div>
          <div class="property-2 row color-1">
            <div
              v-for="landscapePackage in paginationPackages(perPage, currentPage)"
              :key="landscapePackage.id"
              class="col-xl-4 col-md-6 wow fadeInUp mb-4"
            >
              <div class="property-box">
                <div class="property-image">
                  <VueSlickCarousel
                    :arrows="true"
                    :dots="true"
                    :infinite="true"
                    class="property-slider color-1"
                  >
                    <b-link
                      v-for="index_2 in slideImages"
                      :key="index_2"
                      :to="{ name: 'landscape-detail', params: { id: landscapePackage.id } }"
                    >
                      <b-img-lazy
                        :src="landscapePackage.bigImage"
                        class="bg-img"
                        alt=""
                      />
                    </b-link>
                  </VueSlickCarousel>
                </div>

                <div class="property-details">
                  <b-link :to="{ name: 'landscape-detail', params: { id: landscapePackage.id } }" style="height: 50px; margin-bottom: 10px;">
                    <h3>{{landscapePackage.name}}</h3>
                  </b-link>
                  <h6 class="color-1">Liên Hệ: 0834.389.222</h6>
                  <p class="font-roboto">
                    Bài viết về thiết kế cảnh quan
                  </p>
                  <div class="property-btn d-flex">
                    <b-link
                      class="btn btn-dashed btn-pill color-1"
                      :to="{ name: 'landscape-detail', params: { id: landscapePackage.id } }"
                    >
                    {{$t('View more')}}
                    </b-link>
                  </div>
                </div>
              </div>
            </div>
            <nav class="theme-pagination">
            <b-pagination
              v-model="currentPage"
              :total-rows="landscapePackages.length"
              :per-page="perPage"
            ></b-pagination>
          </nav>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { BPagination, BLink, BImgLazy } from "bootstrap-vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  components: {
    VueSlickCarousel,
    BPagination,
    BLink,
    BImgLazy
  },
  data() {
    return{
      perPage: 6,
      currentPage: 1,
      products: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18],
      slideImages:[
        'https://res.cloudinary.com/dlzbwakoc/image/upload/v1665975699/bds_tbd/landspace/Landscape-design-mornington-peninsula_xisuys.webp',
      ]
    }
  },
  computed: {
    paginationPackages() {
      return (page_size, page_number) =>
        this.landscapePackages.slice(
          (page_number - 1) * page_size,
          page_number * page_size
        );
    },
    landscapePackages(){
      return this.$store.state.landscapePackages;
    }
  }
};
</script>

<style scoped>
.bg-img {
  width: 100%;
  object-fit: cover;
  overflow: hidden;
  height: 250px;
}

.property-btn{
  justify-content: space-between;
}
</style>
