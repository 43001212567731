<template>
  <section class="ratio_40">
    <div class="container">
      <div class="row">
        <DetailContent :landscape_id="landscape_id" />
        <RightSidebar />
      </div>
    </div>
  </section>
</template>

<script>
import DetailContent from "../components/landscape-design/DetailContent.vue";
import RightSidebar from "../components/landscape-design/RightSidebar.vue";
export default {
  components: {
    RightSidebar,
    DetailContent
  },
  data() {
    return {
      landscape_id: 0
    }
  },
  created() {
    this.landscape_id = parseInt(this.$route.params.id);
  },
  beforeRouteUpdate(to, from, next) {
    this.landscape_id = to.params.id;
    next();
  },
};
</script>