<template>
  <div>
    <!-- breadcrumb start -->
    <section class="ratio_40 breadcrumb-section p-0 single-property-images">
      <VueSlickCarousel
        :arrows="true"
        :infinite="true"
        :autoplay="true"
        :fade="true"
        class="main-property-slider arrow-image"
      >
        <div v-for="(image, index) in property.images.slice(0, 3)" :key="index">
          <div>
            <b-img :src="image" class="bg-img banner-image" alt="" />
          </div>
        </div>
      </VueSlickCarousel>
      <div class="single-property-section">
        <div class="container">
          <div class="single-title">
            <div class="left-single">
              <div class="d-flex">
                <h2 class="mb-0">{{ property.name }}</h2>
                <span
                  ><span class="label label-shadow ms-2">{{
                    $t("For Sale")
                  }}</span></span
                >
              </div>
              <p class="mt-2">
                {{ property.address }}
              </p>
            </div>
            <div class="right-single">
              <h2 class="price">{{ property.price }}</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- breadcrumb end -->

    <!-- single property start -->
    <section class="single-property">
      <div class="container">
        <div class="row ratio_65">
          <div class="col-xl-9 col-lg-8">
            <div class="description-section">
              <div class="description-details">
                <div class="desc-box">
                  <div class="page-section ratio3_2" id="gallery">
                    <h4 class="content-title">{{ $t("Gallery") }}</h4>
                    <div class="single-gallery">
                      <swiper
                        ref="swiperTop"
                        class="swiper-gallery gallery-top"
                        :options="swiperOptions"
                      >
                        <swiper-slide
                          v-for="(data, index) in property.images"
                          :key="index"
                          class="gallery-top-image"
                        >
                          <b-img-lazy
                            style="width: 100%; margin-top: 35px;"
                            :src="data"
                            fluid
                          />
                        </swiper-slide>
                        <div
                          slot="button-next"
                          class="swiper-button-next swiper-button-white"
                        />
                        <div
                          slot="button-prev"
                          class="swiper-button-prev swiper-button-white"
                        />
                      </swiper>
                      <swiper
                        ref="swiperThumbs"
                        class="swiper gallery-thumbs"
                        :options="swiperOptionThumbs"
                      >
                        <swiper-slide
                          v-for="(data, index) in property.images"
                          :key="index"
                        >
                          <b-img :src="data" fluid class="gallery-thumb" />
                        </swiper-slide>
                      </swiper>
                    </div>
                  </div>
                </div>
                <div class="desc-box" v-if="property.video">
                  <div class="page-section ratio_40" id="video">
                    <h4 class="content-title">Video</h4>
                    <div class="play-bg-image">
                      <div class="bg-size">
                        <iframe
                          title="realestate"
                          :src="property.video"
                          allowfullscreen
                          loading="lazy"
                          allow="encrypted-media"
                          autoplay="false"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="desc-box">
                  <div class="page-section" id="details">
                    <h4 class="content-title">
                      {{ $t("Property Details") }}
                    </h4>
                    <div class="row">
                      <div class="col-md-6">
                        <ul class="property-list-details">
                          <li>
                            <span>{{ $t("Property Type") }} :</span>
                            {{ $t("Property") }}
                          </li>
                          <li>
                            <span>{{ $t("Property status") }} :</span>
                            {{ $t("For sale") }}
                          </li>
                        </ul>
                      </div>
                      <div class="col-md-6">
                        <ul class="property-list-details">
                          <li>
                            <span>{{ $t("Property Size") }} :</span
                            >{{ property.size }}
                          </li>
                          <li>
                            <span>{{ $t("City") }} :</span> {{ $t("Hanoi") }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="desc-box">
                  <div class="page-section" id="floor_plan">
                    <h4 class="content-title">{{ $t("Floor plan") }}</h4>
                    <b-img-lazy
                      :src="property.designImage"
                      alt=""
                      class="img-fluid"
                    />
                  </div>
                </div>
                <div class="desc-box">
                  <div class="page-section" id="location-map">
                    <h4 class="content-title">{{ $t("Location") }}</h4>
                    <iframe
                      title="realestate location"
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7449.2340126489435!2d105.85204215390623!3d21.007984200000006!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135abf63f4cc443%3A0xb75a7f17c3752802!2zVUJORCBwaMaw4budbmcgVGhhbmggTmjDoG4!5e0!3m2!1svi!2s!4v1664764126802!5m2!1svi!2s"
                      allowfullscreen
                      loading="lazy"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4">
            <div class="left-sidebar sticky-cls single-sidebar">
              <div class="filter-cards">
                <div class="advance-card">
                  <h6>{{ $t("Contact Info") }}</h6>
                  <div class="category-property">
                    <div class="agent-info">
                      <div class="media">
                        <b-img
                          src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1663843878/bds_tbd/Favicon-TBD-01_velny4.webp"
                          class="img-50"
                          width="50px"
                          height="50px"
                          rounded="circle"
                          alt=""
                        />
                        <div class="media-body ms-2">
                          <h6 style="font-size: 14px">Nguyễn Tôn Dương</h6>
                          <p>xtdttbd@gmail.com</p>
                        </div>
                      </div>
                    </div>
                    <ul class="ul-info">
                      <li style="margin-bottom: 10px; margin-right: 15px">
                        <b-img
                          src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1663956020/bds_tbd/icons/reshot-icon-location-KZLU2V67R9_j9x4ft.svg"
                          width="16px"
                          height="16px"
                          style="margin-right: 5px"
                        />{{ $t("Hanoi") }}
                      </li>
                      <li class="ul-info-image" style="margin-top: 0">
                        <b-img
                          src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1663955502/bds_tbd/icons/phone-3114_lgk1np.svg"
                          width="16px"
                          height="16px"
                          style="margin-right: 5px"
                        />0834.389.222
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="advance-card">
                  <h6>{{ $t("Request exploration") }}</h6>
                  <div class="category-property">
                    <form @submit.prevent="sendEmail">
                      <div class="form-group">
                        <input
                          name="from_name"
                          type="text"
                          class="form-control"
                          :placeholder="$t('Your Name')"
                          v-model="name"
                          required
                        />
                      </div>
                      <div class="form-group">
                        <input
                          name="from_email"
                          type="email"
                          class="form-control"
                          :placeholder="$t('Email Address')"
                          v-model="email"
                          required
                        />
                      </div>
                      <div class="form-group">
                        <input
                          :placeholder="$t('phone number')"
                          class="form-control"
                          name="from_phone"
                          id="tbNumbers"
                          type="tel"
                          maxlength="10"
                          required=""
                          v-model="phone"
                        />
                      </div>
                      <div class="form-group">
                        <textarea
                          name="message"
                          :placeholder="$t('Message')"
                          class="form-control"
                          rows="3"
                        ></textarea>
                      </div>
                      <button
                        type="submit"
                        class="btn btn-gradient color-2 btn-block btn-pill"
                        style="width: 100%"
                      >
                        {{ $t("Submit Request") }}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- single property end -->

    <!-- property grid start -->
    <PropertiesSection />
    <!-- property grid end -->
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { BImg, BImgLazy } from "bootstrap-vue";
import "swiper/css/swiper.css";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import PropertiesSection from "../components/real-estate/PropertiesSection.vue";
import emailjs from "emailjs-com";
export default {
  components: {
    Swiper,
    SwiperSlide,
    BImg,
    BImgLazy,
    PropertiesSection,
    VueSlickCarousel,
  },
  data() {
    return {
      swiperData: [
        "https://res.cloudinary.com/dlzbwakoc/image/upload/v1663657308/bds_tbd/dcaaf0b46cffa8a1f1ee_qthand.webp",
        "https://res.cloudinary.com/dlzbwakoc/image/upload/v1663657309/bds_tbd/fac687dc0c97c8c99186_qssuim.webp",
        "https://res.cloudinary.com/dlzbwakoc/image/upload/v1663657307/bds_tbd/dfc0b05c3d17f949a006_lv9hc5.webp",
        "https://res.cloudinary.com/dlzbwakoc/image/upload/v1663657306/bds_tbd/09552944b60f72512b1e_t0friq.webp",
        "https://res.cloudinary.com/dlzbwakoc/image/upload/v1663657307/bds_tbd/e6d6e630797bbd25e46a_jnlhxk.webp",
      ],
      swiperOptions: {
        loop: true,
        loopedSlides: 5,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 4,
        touchRatio: 0.2,
        slideToClickedSlide: true,
      },
      bannerImages: [
        "https://res.cloudinary.com/dlzbwakoc/image/upload/v1663657300/bds_tbd/2e83038c9cc7589901d6_hutrzc.webp",
        "https://res.cloudinary.com/dlzbwakoc/image/upload/v1663657300/bds_tbd/2c25dbf953b297eccea3_sad2ie.webp",
        "https://res.cloudinary.com/dlzbwakoc/image/upload/v1663657300/bds_tbd/0ae3ad132558e106b849_pzz9i9.webp",
      ],
      property_id: 0,
      name: "",
      phone: "",
      email: "",
      message: "",
      vnf_regex: /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/,
    };
  },
  created() {
    this.property_id = parseInt(this.$route.params.id);
  },
  mounted() {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.$swiper;
      const swiperThumbs = this.$refs.swiperThumbs.$swiper;
      swiperTop.controller.control = swiperThumbs;
      swiperThumbs.controller.control = swiperTop;
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.property_id = to.params.id;
    next();
  },
  methods: {
    sendEmail(e) {
      if (this.isPhoneNumberValid) {
        try {
          emailjs.sendForm(
            "service_oa3q49l",
            "template_axz46zj",
            e.target,
            "yqsr747vLAuWPCq9y",
            {}
          );
          // Reset form field
          this.name = "";
          this.phone = "";
          this.email = "";
          this.message = "";
          this.$toast.success("Send contact success!");
        } catch (error) {
          console.log({ error });
          this.$toast.error("Send contact failed!");
        }
      } else{
        this.$toast.error("Invalid phone number!");
      }
    },
  },
  computed: {
    property() {
      return this.$store.getters.getPropertyById(this.property_id);
    },
    isPhoneNumberValid() {
      return this.vnf_regex.test(this.phone);
    },
  },
};
</script>

<style scoped>
.gallery-thumbs .swiper-slide {
  opacity: 0.4;
}

.swiper-slide.swiper-slide-active {
  opacity: 1;
}

.gallery-thumbs {
  padding: 10px 0;
  background: #ffffff;
}

.bg-img {
  width: 100%;
  object-fit: cover;
  overflow: hidden;
}

.banner-image {
  height: 500px;
}

.gallery-thumb {
  width: 100%;
  height: 125px;
}

.gallery-top-image {
  object-fit: cover;
  height: 450px;
  overflow: hidden;
}

@media (max-width: 767px) {
  .banner-image {
    height: 250px;
  }

  .gallery-thumb {
    height: 70px;
  }

  .gallery-top-image {
    height: 250px;
    margin-top: 30px;
  }

  .ul-info {
    display: flex;
    justify-content: space-between;
  }
}
</style>
