<template>
  <section class="home-section layout-1">
    <div class="container-fluid">
      <div class="row m-0">
        <div class="col p-0">
          <div class="fnc-slider example-slider">
            <div class="fnc-slider__slides">
              <!-- slide start -->
              <div class="fnc-slide m--blend-green m--active-slide">
                <div class="fnc-slide__inner">
                  <div class="fnc-slide__mask">
                    <div class="fnc-slide__mask-inner"></div>
                  </div>
                  <div class="fnc-slide__content">
                    <h2 class="fnc-slide__heading">
                      <span class="fnc-slide__heading-line">
                        <span>Resort</span>
                      </span>
                      <span class="fnc-slide__heading-line">
                        <span>Real Estate</span>
                      </span>
                    </h2>
                    <b-link
                      :to="{ name: 'contact' }"
                      class="fnc-slide__action-btn"
                    >
                      <span>{{ $t("Contact us") }}</span>
                      <svg width="13px" height="10px" viewBox="0 0 13 10">
                        <path d="M1,5 L11,5"></path>
                        <polyline points="8 1 12 5 8 9"></polyline>
                      </svg>
                    </b-link>
                  </div>
                </div>
              </div>
              <!-- slide end -->
              <!-- slide start -->
              <div class="fnc-slide m--blend-red">
                <div class="fnc-slide__inner">
                  <div class="fnc-slide__mask">
                    <div class="fnc-slide__mask-inner"></div>
                  </div>
                  <div class="fnc-slide__content">
                    <h2 class="fnc-slide__heading">
                      <span class="fnc-slide__heading-line">
                        <span>Resort</span>
                      </span>
                      <span class="fnc-slide__heading-line">
                        <span>Real Estate</span>
                      </span>
                    </h2>
                    <b-link
                      :to="{ name: 'contact' }"
                      class="fnc-slide__action-btn"
                    >
                      <span>{{ $t("Contact us") }}</span>
                      <svg width="13px" height="10px" viewBox="0 0 13 10">
                        <path d="M1,5 L11,5"></path>
                        <polyline points="8 1 12 5 8 9"></polyline>
                      </svg>
                    </b-link>
                  </div>
                </div>
              </div>
              <!-- slide end -->
              <!-- slide start -->
              <div class="fnc-slide m--blend-dark">
                <div class="fnc-slide__inner">
                  <div class="fnc-slide__mask">
                    <div class="fnc-slide__mask-inner"></div>
                  </div>
                  <div class="fnc-slide__content">
                    <h2 class="fnc-slide__heading">
                      <span class="fnc-slide__heading-line">
                        <span>Resort</span>
                      </span>
                      <span class="fnc-slide__heading-line">
                        <span>Real Estate</span>
                      </span>
                    </h2>
                    <b-link
                      :to="{ name: 'contact' }"
                      class="fnc-slide__action-btn"
                    >
                      <span>{{ $t("Contact us") }}</span>
                      <svg width="13px" height="10px" viewBox="0 0 13 10">
                        <path d="M1,5 L11,5"></path>
                        <polyline points="8 1 12 5 8 9"></polyline>
                      </svg>
                    </b-link>
                  </div>
                </div>
              </div>
              <!-- slide end -->
            </div>
            <nav class="fnc-nav">
              <div class="fnc-nav__bgs">
                <div class="fnc-nav__bg m--navbg-green m--active-nav-bg"></div>
                <div class="fnc-nav__bg m--navbg-dark"></div>
                <div class="fnc-nav__bg m--navbg-red"></div>
              </div>
              <div class="fnc-nav__controls">
                <button class="fnc-nav__control">
                  <span class="fnc-nav__control-progress"></span>
                </button>
                <button class="fnc-nav__control">
                  <span class="fnc-nav__control-progress"></span>
                </button>
                <button class="fnc-nav__control">
                  <span class="fnc-nav__control-progress"></span>
                </button>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { BLink } from "bootstrap-vue";
export default {
  components: {
    BLink,
  },
  data() {
    return {
      swiperOptions: {
        loop: true,
        effect: "fade",
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
        },
      },
    };
  },
  mounted() {
    let sliderScript = document.createElement("script");
    sliderScript.setAttribute("src", "./assets/js/fancy-slider.js");
    document.head.appendChild(sliderScript);
  },
};
</script>
