<template>
  <section class="provide-service bg-light">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="title-1">
            <span class="label label-gradient color-6">{{
              $t("About us")
            }}</span>
            <h2>{{ $t("About our company") }}</h2>
            <hr />
          </div>
        </div>
        <div class="col-md-12 col-xl-5">
          <div class="provide-details pb-4">
            <h1 class="pb-2">
              {{ $t("PACIFIC TRADING AND TECHNOLOGY LIMITED COMPANY") }}
            </h1>
            <h5
              v-for="(introduce, index) in introduces"
              :key="index"
              class="font-roboto provide-details-front"
            >
              <b-img
                style="width: 25px;margin-bottom: 3px;height: 25px;"
                src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1664110084/bds_tbd/icons/rhombus_dsahdz.svg"
                alt="origin-image"
              />
              {{ $t(introduce) }}
            </h5>
          </div>
        </div>
        <div class="col-md-12 col-xl-6 offset-lg-1 order-lg-1">
          <div class="service-img">
            <b-img
              :src="`${publicImagePath}main/anh7_r8nn2s.webp`"
              class="img-fluid"
              alt="origin-image"
              style="width: 100%;"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { BImg } from "bootstrap-vue";
export default {
  components: {
    BImg,
  },
  data() {
    return {
      introduces: [
        "Pacific Group is one of the leading enterprises in the field of consulting and construction of Homestay projects in Vietnam. Our company specializes in providing customers with the following services: From real estate investment consulting to design, construction, and project management and operation packages; Procedures for planning funding, project licensing, surveying, design, construction supervision...",
        "With more than 10 years of experience in consulting; With a team of executive managers, experts and engineers with high professional qualifications, experience, dynamism, creativity, intensive training at home and abroad; With the leading experts in each field, ensuring to provide diverse consulting services, meeting all requirements of customers. Coming to Cong Thai Binh Duong, customers are assured of the quality of consulting services to the stage of project operation and exploitation."
      ],
    };
  },
  computed: {
    publicImagePath() {
      return this.$store.state.publicImagePath;
    },
  },
};
</script>

<style scoped>
.service-img img{
  height: auto;
  border-radius: 10px;
}
</style>
