<template>
  <section class="banner-section layout9 parallax-image">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="title-1">
            <span class="label label-gradient color-1">{{$t('Objective')}}</span>
          </div>
          <div class="banner-1">
            <span class="big-gradient">*</span>
            <h2 class="text-center">{{ $t('Motto & objective') }}</h2>
            <h6>
              <b-img
                class="icon-description"
                width="32px"
                height="32px"
                alt="icon"
                src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1664110084/bds_tbd/icons/rhombus_dsahdz.svg"
              />
              {{$t('The goal of the company is to develop comprehensively and sustainably resort models to form a closed ecosystem, including models of entertainment, experience tourism, nursing care center for the community. community, especially the elderly... replicating the model to many provinces and cities across the country.')}}
            </h6>
            <h6>
              <b-img
                class="icon-description"
                width="32px"
                height="32px"
                alt="icon"
                src="https://res.cloudinary.com/dlzbwakoc/image/upload/v1664110084/bds_tbd/icons/rhombus_dsahdz.svg"
              />
              {{$t("The Company's motto of action is to create trust to meet the trust of the Company is to create trust to satisfy the trust of customers. The company would like to send respectful greetings and look forward to receiving the cooperation of customers.")}}
            </h6>
            <div class="button-banner">
              <b-link
                :to="{ name: 'contact'}"
                class="btn btn-gradient btn-pill color-1"
                >{{$t('Contact us')}}</b-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { BImg, BLink } from "bootstrap-vue";
export default {
  components: {
    BImg,
    BLink
  },
};
</script>

<style>

</style>
