<template>
  <section class="my-gallery">
    <CoolLightBox
      :items="images"
      :index="imageIndex"
      :fullScreen="true"
      :useZoomBar="true"
      :enableWheelEvent="true"
      @close="imageIndex = null"
    >
    </CoolLightBox>
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="title-1">
            <span class="label label-gradient color-6">{{ $t("Photos") }}</span>
            <h2>{{ $t("Company pictures") }}</h2>
            <hr />
          </div>
          <div class="row">
            <div
              v-for="(image, index) in gallery"
              :key="index"
              :class="image.class"
              class="grid-item"
              @click="imageIndex = index"
              style="cursor: pointer;"
            >
              <div class="find-cities bg-size">
                <b-img-lazy :src="`${publicImagePath}main/${image.image}`" class="bg-img" alt="my-gallery" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import { BImgLazy } from "bootstrap-vue";
export default {
  components: {
    BImgLazy,
    CoolLightBox,
  },
  data() {
    return {
      gallery: [
        {
          class: "col-12 col-md-3",
          image:
            "anh1_w7pofq.webp",
        },
        {
          class: "col-12 col-md-3",
          image:
            "anh2_egq2xn.webp",
        },
        {
          class: "col-12 col-md-3",
          image:
            "anh3_y7qvcz.webp",
        },
        {
          class: "col-12 col-md-3",
          image:
            "anh4_phmfxb.webp",
        },
        {
          class: "col-12 col-md-3",
          image:
            "anh5_zjf6vv.webp",
        },
        {
          class: "col-12 col-md-3",
          image:
            "anh6_us0vor.webp",
        },
        {
          class: "col-12 col-md-3",
          image:
            "anh7_r8nn2s.webp",
        },
        {
          class: "col-12 col-md-3",
          image:
            "anh8_jpwlrs.webp",
        },
        {
          class: "col-12 col-md-3",
          image:
            "anh9_ba7tnh.webp",
        },
        {
          class: "col-12 col-md-3",
          image:
            "anh10_snrnli.webp",
        },
        {
          class: "col-12 col-md-3",
          image:
            "anh11_tniqzr.webp",
        },
        {
          class: "col-12 col-md-3",
          image:
            "anh12_wfbm5h.webp",
        },
      ],
      imageIndex: null,
      images: [],
    };
  },
  mounted() {
    this.gallery.forEach((item) => this.images.push(this.publicImagePath + "main/" + item.image));
  },
  computed:{
    publicImagePath(){
      return this.$store.state.publicImagePath;
    }
  }
};
</script>

<style scoped>
.bg-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.grid-item:hover .bg-img {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
}
</style>
